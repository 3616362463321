import {
  Box,
  FormControl,
  Grid,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import {
  Control,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useEffect, useState } from "react";

import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import FormInputMultiCheckbox from "../../../../../components/formComponents/FormInputMultiCheckbox";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { FaxFormOption } from "../../../../../models/Master";
import {
  CheckboxListType,
  generateCheckboxData,
} from "../../../../../components/formComponents/FormInputMultiCheckbox";
import { getList } from "../../../../../utils";
import { rules } from "../../../../../utils/validation/Validation";
import {
  FAX_OTHER_REASON_STATED,
  FaxFormTemplate,
  faxMasterDataList,
  HomeHealthServicesDescription,
  NoticeToReferralDescription,
} from "../../../../../constants/Fax";
import { HomeHealthServicesCheckbox } from "../styles/SendFaxTemplates";
import { SendFax } from "../../../../../models/Authorization";
import { mandatoryFieldLabel } from "../../../../../components/custom/mandatoryFieldLabel/MandatoryFieldLabel";
import { DateFormControl, DateTimeGrid } from "../styles/Disciplines";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";

interface PropsFromState {
  control: Control<SendFax> | undefined;
  patientName: string;
  patientDob: string;
  homeHealthServices: FaxFormOption[];
  setValue: UseFormSetValue<any>;
  showDateTime?: boolean;
  physicianDateTime: any;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  clearErrors: UseFormClearErrors<any>;
}

interface PartialApprovalPeerToPeerProps extends PropsFromState {}

export const Template = ({
  control,
  patientName,
  patientDob,
  homeHealthServices,
  noticeToReferral,
  showDateTime,
  setValue,
  physicianDateTime,
  watch,
  clearErrors,
  trigger,
}: PropsFromState & {
  noticeToReferral: FaxFormOption[];
}) => {
  const [homeHealthServicesList, setHomeHealthServicesList] = useState<
    CheckboxListType[]
  >([]);
  const [noticeToReferralList, setNoticeToReferralList] = useState<
    CheckboxListType[]
  >([]);
  const [otherReasonChecked, setOtherReasonChecked] = useState<boolean>(false);

  useEffect(() => {
    const homeHealthServicesCheckboxList = getList(
      homeHealthServices,
      faxMasterDataList
    );
    const homeHealthServicesList: CheckboxListType[] = generateCheckboxData(
      homeHealthServicesCheckboxList
    );
    setHomeHealthServicesList(homeHealthServicesList);
  }, [homeHealthServices]);

  useEffect(() => {
    const noticeToReferralCheckboxList = getList(
      noticeToReferral,
      faxMasterDataList
    );
    const noticeToReferralList: CheckboxListType[] = generateCheckboxData(
      noticeToReferralCheckboxList
    );
    setNoticeToReferralList(noticeToReferralList);
  }, [noticeToReferral]);

  useEffect(() => {
    const otherReasonCheckbox = noticeToReferralList.find((item) =>
      item.label.includes(FAX_OTHER_REASON_STATED)
    );

    if (otherReasonCheckbox) {
      setOtherReasonChecked(otherReasonCheckbox.checked);
    }
  }, [noticeToReferralList]);

  const otherReasonDetailsName = FaxFormTemplate.OTHER_REASON;

  return renderHumanaAndOtherTemplate(
    control,
    setValue,
    patientName,
    patientDob,
    homeHealthServicesList,
    noticeToReferralList,
    setHomeHealthServicesList,
    setNoticeToReferralList,
    otherReasonDetailsName,
    otherReasonChecked,
    physicianDateTime,
    watch,
    clearErrors,
    trigger,
    showDateTime
  );
};

export const HumanaPeerToPeer = Template;

export const OtherHealthPlans = Template;

export const PartialApprovalPeerToPeer = ({
  control,
  setValue,
  patientName,
  patientDob,
  homeHealthServices,
}: PartialApprovalPeerToPeerProps) => {
  const [homeHealthServicesList, setHomeHealthServicesList] = useState<
    CheckboxListType[]
  >([]);

  useEffect(() => {
    const homeHealthServicesCheckboxList = getList(
      homeHealthServices,
      faxMasterDataList
    );
    const homeHealthServicesList: CheckboxListType[] = generateCheckboxData(
      homeHealthServicesCheckboxList
    );
    setHomeHealthServicesList(homeHealthServicesList);
  }, [homeHealthServices]);

  const otherReasonDetailsName = FaxFormTemplate.OTHER_REASON;

  return (
    <Box>
      <Grid container>
        {patientDetails(patientName, patientDob)}
        {homeHealthServicesSection(
          control,
          setValue,
          homeHealthServicesList,
          setHomeHealthServicesList
        )}
        <Grid item xs={12}>
          <Box pt={"1.4rem"} display={"flex"}>
            {typography(
              "subtitle1",
              fontWeight.Weight[6],
              colors.fonts[20],
              "Notice To Ordering Prescriber: "
            )}
            {mandatoryFieldLabel()}
          </Box>
          <Box pt={"0.4rem"}>
            {typography(
              "body1",
              fontWeight.Weight[4],
              colors.black[4],
              HomeHealthServicesDescription
            )}
          </Box>
          <Box pt={"1.8rem"} pb={"1rem"}>
            <Box display={"flex"}>
              <Typography
                variant="subtitle1"
                fontWeight={fontWeight.Weight[2]}
                color={colors.fonts[2]}
              >
                Reason Stated Below:
              </Typography>
              {mandatoryFieldLabel()}
            </Box>
            <Box pt={"1rem"} pb={"1rem"}>
              <FormInputText
                control={control}
                name={otherReasonDetailsName}
                inputRows={2}
                multiline={true}
                inputVariant="outlined"
                textLength={500}
                helper={rules.faxOtherReason}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const renderHumanaAndOtherTemplate = (
  control: Control<SendFax> | undefined,
  setValue: UseFormSetValue<any>,
  patientName: string,
  patientDob: string,
  homeHealthServicesList: CheckboxListType[],
  noticeToReferralList: CheckboxListType[],
  setHomeHealthServicesList: (list: CheckboxListType[]) => void,
  setNoticeToReferralList: (list: CheckboxListType[]) => void,
  otherReasonDetailsName: string,
  otherReasonChecked: boolean,
  physicianDateTime: any,
  watch: UseFormWatch<any>,
  clearErrors: UseFormClearErrors<any>,
  trigger: UseFormTrigger<any>,
  showDateTime?: boolean
) => (
  <Box>
    <Grid container>
      {patientDetails(patientName, patientDob)}
      {homeHealthServicesSection(
        control,
        setValue,
        homeHealthServicesList,
        setHomeHealthServicesList
      )}
      <Grid item xs={12}>
        <Box pt={"1.4rem"} display={"flex"}>
          {typography(
            "subtitle1",
            fontWeight.Weight[6],
            colors.fonts[20],
            "Notice To Referral: "
          )}
          {mandatoryFieldLabel()}
        </Box>
        <Box pt={"0.4rem"}>
          {typography(
            "body1",
            fontWeight.Weight[4],
            colors.black[4],
            NoticeToReferralDescription
          )}
        </Box>
        <Box pt={"1rem"}>
          <FormInputMultiCheckbox
            control={control}
            setValue={setValue}
            name={FaxFormTemplate.NOTICE_TO_REFERRAL}
            options={noticeToReferralList}
            setState={setNoticeToReferralList}
            rules={rules.noticeToReferral}
            gridSize={12}
          />
        </Box>
        {otherReasonChecked && (
          <Box pt={"1rem"} pb={"1rem"} display={"flex"}>
            <FormInputText
              control={control}
              name={otherReasonDetailsName}
              inputRows={2}
              multiline={true}
              inputVariant="outlined"
              helper={rules.faxOtherReason}
              textLength={500}
            />
            {mandatoryFieldLabel()}
          </Box>
        )}
        {showDateTime === true && (
          <>
            <Box pt={"1.4rem"} display={"flex"}>
              {typography(
                "subtitle1",
                fontWeight.Weight[6],
                colors.fonts[20],
                "Physicians Only: "
              )}
            </Box>
            <Grid item xs={12} sx={DateTimeGrid}>
              <Grid container sx={flexAlignCentre}>
                <Grid item xs={0.9}>
                  {typography(
                    "subtitle1",
                    fontWeight.Weight[4],
                    colors.fonts[20],
                    "Call by: "
                  )}
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="standard" sx={DateFormControl}>
                    <DatePicker
                      name="physicianDateTime"
                      control={control}
                      label={"Date:"}
                      helper={rules.dateTimeRequired}
                      allowFutureDate={true}
                      watch={watch}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="standard">
                    <FormInputTimePicker
                      name="physicianDateTime"
                      control={control}
                      setValue={setValue}
                      label="Time:"
                      helper={rules.timeRequired}
                      allowFutureTime={true}
                      value={physicianDateTime}
                      watch={watch}
                      trigger={trigger}
                      clearErrors={clearErrors}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  </Box>
);
const patientDetails = (patientName: string, patientDob: string) => (
  <>
    <Grid item xs={12}>
      <Box sx={flexAlignCentre}>
        <Box>
          {typography(
            "subtitle1",
            fontWeight.Weight[6],
            colors.fonts[20],
            "Patient Name: "
          )}
        </Box>
        <Box pl={"0.5rem"}>
          {typography(
            "subtitle1",
            fontWeight.Weight[6],
            colors.fonts[20],
            patientName
          )}
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box sx={flexAlignCentre} pt={"1.4rem"}>
        <Box>
          {typography(
            "subtitle1",
            fontWeight.Weight[6],
            colors.fonts[20],
            "DOB: "
          )}
        </Box>
        <Box pl={"0.5rem"}>
          {typography(
            "subtitle1",
            fontWeight.Weight[6],
            colors.fonts[20],
            patientDob
          )}
        </Box>
      </Box>
    </Grid>
  </>
);

const homeHealthServicesSection = (
  control: Control<SendFax> | undefined,
  setValue: UseFormSetValue<any>,
  homeHealthServicesList: CheckboxListType[],
  setHomeHealthServicesList: (list: CheckboxListType[]) => void
) => (
  <Grid item xs={12}>
    <Box pt={"1.4rem"} display={"flex"}>
      {typography(
        "subtitle1",
        fontWeight.Weight[6],
        colors.fonts[20],
        "Home Health Services: "
      )}
      {mandatoryFieldLabel()}
    </Box>
    <Box pt={"0.8rem"} ml={"0.29rem"}>
      <Grid container>
        <FormInputMultiCheckbox
          control={control}
          setValue={setValue}
          name={FaxFormTemplate.HOME_HEALTH_SERVICES}
          options={homeHealthServicesList}
          setState={setHomeHealthServicesList}
          rules={rules.homeHealthServices}
          style={HomeHealthServicesCheckbox}
          gridSize={4}
        />
      </Grid>
    </Box>
  </Grid>
);

const typography = (
  variant:
    | OverridableStringUnion<
        Variant | "inherit",
        TypographyPropsVariantOverrides
      >
    | undefined,
  weight: string,
  color: string,
  value: string
) => (
  <Typography variant={variant} fontWeight={weight} color={color}>
    {value}
  </Typography>
);
