import {
  Box,
  Divider,
  Grid,
  Stack,
  Switch,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { isNil } from "lodash";
import { OverridableStringUnion } from "@mui/types";
import { useEffect, useState } from "react";
import { Variant } from "@mui/material/styles/createTypography";

import {
  FollowingPhysicianDetails,
  PhysicianDetails,
  ReferralIntakePhysicianDetails,
} from "../../../../../models/PhysicianDetails";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  Body,
  Header,
  HeaderBorder,
  HeaderBorderPrimary,
  MainContainer,
  Pad,
  Subtitle,
  Title,
} from "../../ReferralIntake/styles/OrderingPhysician";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { justifyContentLeft } from "../../../../../styles/mui/styles/display";
import PhysicianInputForm from "../../ReferralIntake/containers/PhysicianInputForm";
import {
  followingPhysicianFormNames,
  NppesAddManuallyTypes,
  PhysicianTypeID,
  primaryPhysicianInitialData,
} from "../../../../../constants/PhysicianDetails";
import { AvailablePhysicianDetails } from "../../ReferralIntake/components/AvailablePhysicianDetails";
import { ReauthOpacity } from "../../ReferralIntake/styles/style";
import { getFormattedPhoneNo, getValue } from "../../../../../utils";

export interface PropsFromState {
  control: any;
  followingPhysicianDetailsResp: FollowingPhysicianDetails;
  followingPhysicianResp?: any;
  getValues: any;
  physicianDetailsResp: ReferralIntakePhysicianDetails;
  reset: any;
  setValue: any;
  watch: any;
  resetPhysicianRecords: () => void;
}

type AllProps = PropsFromState;

const FollowingPhysician: React.FC<AllProps> = ({
  control,
  followingPhysicianDetailsResp,
  getValues,
  physicianDetailsResp,
  reset,
  setValue,
  resetPhysicianRecords,
}: AllProps) => {
  const [orderingPhysician, setOrderingPhysician] = useState<PhysicianDetails>(
    physicianDetailsResp && physicianDetailsResp.orderingPhysician
  );
  const [primaryPhysician, setPrimaryPhysician] = useState<PhysicianDetails>(
    physicianDetailsResp && physicianDetailsResp.primaryPhysician
  );
  const [switchSameAsPrimary, setSwitchSameAsPrimary] =
    useState<boolean>(false);
  const [switchSameAsOrdering, setSwitchSameAsOrdering] =
    useState<boolean>(false);
  const [followingPhysician, setFollowingPhysician] =
    useState<PhysicianDetails>();
  const [
    addFollowingPhysicianDetailsManually,
    setAddFollowingPhysicianDetailsManually,
  ] = useState<boolean>(false);

  const [openFollowingPhysician, setOpenFollowingPhysician] = useState(false);
  const [
    openFollowingPhysicianAutocomplete,
    setOpenFollowingPhysicianAutocomplete,
  ] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  useEffect(() => {
    resetPhysicianRecords();
  }, []);

  useEffect(() => {
    if (getValue(physicianDetailsResp, "primaryPhysician.physicianId") > 0) {
      setPrimaryPhysician(physicianDetailsResp.primaryPhysician);
    }

    if (getValue(physicianDetailsResp, "orderingPhysician.physicianId") > 0) {
      setOrderingPhysician(physicianDetailsResp.orderingPhysician);
    }
  }, [physicianDetailsResp]);

  useEffect(() => {
    if (followingPhysicianDetailsResp) {
      if (isNil(followingPhysicianDetailsResp.physicianId)) {
        setAddFollowingPhysicianDetailsManually(true);
      } else {
        setAddFollowingPhysicianDetailsManually(false);
        setFollowingPhysician({
          ...followingPhysicianDetailsResp,
          physicianAddress:
            followingPhysicianDetailsResp.physicianAddress || "",
        });
      }
    }
  }, [followingPhysicianDetailsResp]);

  const checkSameAsPrimary = (
    followingPhysicianDetailsResp: FollowingPhysicianDetails
  ) => {
    if (
      getValue(followingPhysicianDetailsResp, "physicianSameAsPrimary") === true
    ) {
      setSwitchSameAsPrimary(true);
      setSwitchSameAsOrdering(false);
      handleSwitch(true, "same as primary", !switchSameAsPrimary, false);
    } else {
      setSwitchSameAsPrimary(false);
    }
  };

  const checkSameAsOrdering = (
    followingPhysicianDetailsResp: FollowingPhysicianDetails
  ) => {
    if (
      getValue(followingPhysicianDetailsResp, "physicianSameAsOrdering") ===
      true
    ) {
      setSwitchSameAsOrdering(true);
      setSwitchSameAsPrimary(false);
      handleSwitch(true, "same as ordering", !switchSameAsOrdering, false);
    } else {
      setSwitchSameAsOrdering(false);
      setIsSearchVisible(true);
    }
  };

  useEffect(() => {
    checkSameAsPrimary(followingPhysicianDetailsResp);
    checkSameAsOrdering(followingPhysicianDetailsResp);
  }, [physicianDetailsResp, followingPhysicianDetailsResp]);

  const handleResetDetails = () => {
    reset({
      ...getValues(),
      physicianName: "",
      physicianNpiId: "",
      taxId: "",
      streetName1: "",
      streetName2: "",
      city: "",
      county: "",
      physicianState: "",
      zipCode: "",
      phoneNo: "",
      phoneExt: "",
      physicianId: 0,
      fax: "",
      followingPhysicianId: 0,
      physicianAddress: "",
    });
  };
  const followingPhysicianAsPrimary = (
    checked: boolean,
    physicianDetailsResp: any,
    type: number
  ) => {
    let followingPhysicianUpdate = physicianDetailsResp.orderingPhysician;
    const physicianTypeId = PhysicianTypeID.FOLLOWING;
    const followingPhyID = getValue(
      followingPhysicianDetailsResp,
      "followingPhysicianId",
      0
    );
    
    followingPhysicianUpdate = {
      ...followingPhysicianUpdate,
    }

    // primary
    if (type === 1) {
      followingPhysicianUpdate = physicianDetailsResp.primaryPhysician;
    }

    followingPhysicianUpdate = {
      ...followingPhysicianUpdate,
      physicianTypeId,
    }

    if (checked) {
      setIsSearchVisible(true);
      handleResetDetails();
      setFollowingPhysician({
        ...followingPhysicianUpdate,
      });
      
      setValue("followingPhysicianId", followingPhyID);

      reset({
        ...getValues(),
        ...followingPhysicianUpdate,
      });
    } else {
      setIsSearchVisible(false);
    }
    const toggleKeys = {
      physicianSameAsPrimary: false,
      physicianSameAsOrdering: false,
    };
    // primary
    if (type === 1) {
      if (checked) {
        toggleKeys.physicianSameAsPrimary = true;
        toggleKeys.physicianSameAsOrdering = false;
      } else {
        toggleKeys.physicianSameAsPrimary = false;
      }
      // ordering
    } else if (type === 2) {
      if (checked) {
        toggleKeys.physicianSameAsPrimary = false;
        toggleKeys.physicianSameAsOrdering = true;
      } else {
        toggleKeys.physicianSameAsOrdering = false;
      }
    }
    reset({
      ...getValues(),
      ...toggleKeys,
    });
  };

  const handleSwitch = (
    checked: boolean,
    value: string,
    flag: boolean,
    onChange: boolean
  ) => {
    if ("same As Primary" === value) {
      !onChange && !switchSameAsPrimary && setSwitchSameAsPrimary(flag);
      switchSameAsOrdering && setSwitchSameAsOrdering(!flag);
      setAddFollowingPhysicianDetailsManually(false);
      followingPhysicianAsPrimary(checked, physicianDetailsResp, 1);
    } else if ("same as ordering" === value) {
      !onChange && !switchSameAsOrdering && setSwitchSameAsOrdering(flag);
      switchSameAsPrimary && setSwitchSameAsPrimary(!flag);
      setAddFollowingPhysicianDetailsManually(false);
      followingPhysicianAsPrimary(checked, physicianDetailsResp, 2);
    }
    onChange &&
      ("same As Primary" === value
        ? setSwitchSameAsPrimary(flag)
        : setSwitchSameAsOrdering(flag));
  };
  const handleToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
    flag: boolean
  ) => {
    handleSwitch(event.target.checked, value, flag, true);
    setIsReadOnly(true);
  };
  const chooseFollowingPhysician = (option: any) => {
    setFollowingPhysician(option);
    checkSameAsPrimary(option);
    checkSameAsOrdering(option);
    if (!isNil(option)) {
      const {
        physicianName,
        physicianNpiId,
        taxId,
        city,
        physicianState,
        zipCode,
        streetName1,
        streetName2,
        fax,
        physicianAddress,
        county,
        phoneExt,
        phoneNo,
      } = option;

      reset({
        ...getValues(),
        physicianName: physicianName,
        physicianNpiId: physicianNpiId,
        taxId: taxId,
        streetName1: streetName1,
        streetName2: streetName2,
        city: city,
        county: county,
        physicianState: physicianState,
        zipCode: zipCode,
        physicianId: getValue(followingPhysicianDetailsResp, "physicianId", 0),
        followingPhysicianId: getValue(
          followingPhysicianDetailsResp,
          "followingPhysicianId",
          0
        ),
        fax: getFormattedPhoneNo(fax),
        physicianAddress: physicianAddress,
        phoneExt: phoneExt,
        phoneNo: phoneNo ? getFormattedPhoneNo(phoneNo) : "",
      });
    }
    setAddFollowingPhysicianDetailsManually(false);
    setIsReadOnly(false);
  };
  const resetToEmptyDetails = () => {
    setFollowingPhysician(undefined);
    reset({
      ...getValues(),
      physicianName: "",
      physicianNpiId: "",
      taxId: "",
      streetName1: "",
      streetName2: "",
      city: "",
      county: "",
      physicianState: "",
      zipCode: "",
      physicianId: getValue(followingPhysicianDetailsResp, "physicianId", 0),
      fax: "",
      followingPhysicianId: getValue(
        followingPhysicianDetailsResp,
        "followingPhysicianId",
        0
      ),
      phoneNo: "",
      phoneExt: "",
      physicianSameAsOrdering: false,
      physicianSameAsPrimary: false,
    });
  };
  const handleAddFollowingPhysicianManually = () => {
    const val = addFollowingPhysicianDetailsManually;
    setAddFollowingPhysicianDetailsManually(!val);
    setSwitchSameAsOrdering(false);
    setSwitchSameAsPrimary(false);
    if (!val) {
      setFollowingPhysician(undefined);
      resetToEmptyDetails();
    }
    setFollowingPhysician(undefined);
    setIsReadOnly(false);
  };
  const TypographyBox = (
    variant:
      | OverridableStringUnion<
          "inherit" | Variant,
          TypographyPropsVariantOverrides
        >
      | undefined,
    value: string,
    color: string,
    fontWeight: string,
    sx?: object
  ) => {
    return (
      <Typography
        variant={variant}
        fontWeight={fontWeight}
        color={color}
        sx={sx}
      >
        {value}
      </Typography>
    );
  };
  const header = (title: string, subtitle: string) => {
    return (
      <Grid item xs={4}>
        <Box sx={Title}>
          {TypographyBox("h6", title, colors.fonts[20], fontWeight.Weight[5])}
        </Box>
        <Box sx={Subtitle}>
          {TypographyBox(
            "body1",
            subtitle,
            colors.fonts[5],
            fontWeight.Weight[3],
            ReauthOpacity
          )}
        </Box>
      </Grid>
    );
  };

  useEffect(() => {
    if (!isSearchVisible) {
      resetToEmptyDetails();
      setIsReadOnly(false);
    }
  }, [isSearchVisible]);

  return (
    <>
      <Grid container sx={MainContainer}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sx={HeaderBorderPrimary}>
              <Grid container sx={Header}>
                {header(
                  "PRIMARY PHYSICIAN DETAILS:",
                  "Please add physician details and continue:"
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={Body}>
              <AvailablePhysicianDetails
                physicianDetails={
                  primaryPhysician || primaryPhysicianInitialData
                }
                modal={false}
              />
            </Grid>
            <Grid item xs={12} pb={"1rem"}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sx={HeaderBorder}>
              <Grid container sx={Header}>
                {header(
                  "ORDERING PHYSICIAN DETAILS:",
                  "Please add physician details and continue:"
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={[Body]}>
              {orderingPhysician && (
                <AvailablePhysicianDetails
                  physicianDetails={orderingPhysician}
                  modal={false}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={"0.5rem"}>
          <Divider />
        </Grid>
        <Grid item xs={12} pt="0.4rem">
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={Header}>
                {header(
                  "FOLLOWING PHYSICIAN DETAILS:",
                  "Please add physician details and continue:"
                )}
                <Grid item xs={8}>
                  <Box sx={Title}></Box>
                  <Box sx={[flexAlignCentre, justifyContentLeft]}>
                    {getValue(primaryPhysician, "physicianName") && (
                      <Box>
                        <Stack
                          direction="row"
                          spacing={3}
                          alignItems="center"
                          sx={Pad}
                        >
                          <Switch
                            value={switchSameAsPrimary}
                            inputProps={{ "aria-label": "ant design" }}
                            onChange={(e) =>
                              handleToggle(
                                e,
                                "same As Primary",
                                !switchSameAsPrimary
                              )
                            }
                            checked={switchSameAsPrimary}
                          />
                          {TypographyBox(
                            "subtitle1",
                            "Same as primary physician",
                            colors.fonts[4],
                            fontWeight.Weight[4]
                          )}
                        </Stack>
                      </Box>
                    )}
                    <Box>
                      <Stack
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        sx={Pad}
                      >
                        <Switch
                          value={switchSameAsOrdering || false}
                          inputProps={{ "aria-label": "ant design" }}
                          onChange={(e) =>
                            handleToggle(
                              e,
                              "same as ordering",
                              !switchSameAsOrdering
                            )
                          }
                          checked={switchSameAsOrdering || false}
                        />
                        {TypographyBox(
                          "subtitle1",
                          "Same as ordering physician",
                          colors.fonts[4],
                          fontWeight.Weight[4]
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={Body} mt={"1.5rem"}>
              <PhysicianInputForm
                control={control}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                choosePhysician={chooseFollowingPhysician}
                addManually={addFollowingPhysicianDetailsManually}
                handleAddManually={handleAddFollowingPhysicianManually}
                physician={followingPhysician || null}
                names={followingPhysicianFormNames}
                setOpen={setOpenFollowingPhysician}
                open={openFollowingPhysician}
                name={"followingPhysicianName"}
                setOpenAutocomplete={setOpenFollowingPhysicianAutocomplete}
                openAutocomplete={openFollowingPhysicianAutocomplete}
                hideAddManually={NppesAddManuallyTypes.SHOW_HIDE_MANUALLY_SIDE} // assign value for autoComplete
                isReadOnly={isReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FollowingPhysician;
