import {
  Box,
  Divider,
  Grid,
  Stack,
  Switch,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { UseFormSetValue, useWatch } from "react-hook-form";

import {
  formatAddress,
  PhysicianDetails,
  ReferralIntakePhysicianDetails,
} from "../../../../../models/PhysicianDetails";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  Body,
  Header,
  Subtitle,
  Title,
  Pad,
  MainContainer,
} from "../../ReferralIntake/styles/OrderingPhysician";
import PhysicianInputForm from "../../ReferralIntake/containers/PhysicianInputForm";
import {
  NppesAddManuallyTypes,
  PhysicianTypeID,
  primaryPhysicianFormNames,
} from "../../../../../constants/PhysicianDetails";
import { getFormattedPhoneNo, getValue, isEmptyValue } from "../../../../../utils";
import { fieldNames, getFormValues } from "../../../components/ActiveAuthCommon/PatientHeader/components";

export const initialOrderingPhysicianAddress = {
  streetName: "",
  city: "",
  county: "",
  country: "",
  patientState: "",
  zipCode: "",
};

export interface PropsFromState {
  control: any;
  getValues: any;
  setValue: any;
  reset: any;
  watch: any;
  physicianDetailsResp: ReferralIntakePhysicianDetails;
  resetPhysicianRecords: () => void;
  clearErrors: any;
}

type AllProps = PropsFromState;

const OrderingPhysician: React.FC<AllProps> = ({
  getValues,
  setValue,
  reset,
  watch,
  control,
  physicianDetailsResp,
  resetPhysicianRecords,
}: AllProps) => {
  const [orderingPhysician, setOrderingPhysician] =
    useState<PhysicianDetails>();
  const [primaryPhysician, setPrimaryPhysician] = useState<PhysicianDetails>();
  const [
    addOrderingPhysicianDetailsManually,
    setAddOrderingPhysicianDetailsManually,
  ] = useState<boolean>(
    isEmpty(getValues("orderingPhysician.physicianName")) ? true : false
  );
  const [
    addPrimaryPhysicianDetailsManually,
    setAddPrimaryPhysicianDetailsManually,
  ] = useState<boolean>(
    isEmpty(getValues("primaryPhysician.physicianName")) ? true : false
  );
  const [openPrimaryPhysician, setOpenPrimaryPhysician] = useState(false);
  const [
    openPrimaryPhysicianAutocomplete,
    setOpenPrimaryPhysicianAutocomplete,
  ] = useState(false);
  const [openOrderingPhysician, setOpenOrderingPhysician] = useState(false);
  const [
    openOrderingPhysicianAutocomplete,
    setOpenOrderingPhysicianAutocomplete,
  ] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [noValidate, setNoValidate] = useState<boolean>(true);

  useEffect(() => {
    if (physicianDetailsResp) {
      const { primaryPhysician, orderingPhysician } = physicianDetailsResp;
      if (
        getValue(primaryPhysician, "physicianId") != -1 &&
        getValue(primaryPhysician, "physicianId") != null
      ) {
        setPrimaryPhysician(primaryPhysician);
        setAddPrimaryPhysicianDetailsManually(false);
      }
      if (
        getValue(orderingPhysician, "physicianId") != -1 &&
        getValue(orderingPhysician, "physicianId") != null
      ) {
        orderingPhysician && setOrderingPhysician(orderingPhysician);
        setAddOrderingPhysicianDetailsManually(false);
      }
      reset(physicianDetailsResp);
      setToggleState(getValue(orderingPhysician, "physicianSameAsPrimary"));
    }
  }, [physicianDetailsResp]);

  useEffect(() => {
    resetPhysicianRecords();
  }, []);

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (value && name && value.primaryPhysician) {
        handleOrderingPhysicianState(name, value, setValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues("orderingPhysician.physicianSameAsPrimary")]);

  // showing Ordering Physician details manual section
  useEffect(() => {
    if (getValues("orderingPhysician.physicianSameAsPrimary")) {
      setAddOrderingPhysicianDetailsManually(
        addPrimaryPhysicianDetailsManually
      );
    }
  }, [
    getValues("orderingPhysician.physicianSameAsPrimary"),
    addPrimaryPhysicianDetailsManually,
    addOrderingPhysicianDetailsManually,
  ]);

  // for primaryPhysician address change
  useEffect(() => {
    const primaryStreetName1 = getValues("primaryPhysician.streetName1");
    const primaryStreetName2 = getValues("primaryPhysician.streetName2");
    const primaryCity = getValues("primaryPhysician.city");
    const primaryState = getValues("primaryPhysician.physicianState");
    const primaryZipCode = getValues("primaryPhysician.zipCode");

    const address = formatAddress(
      primaryStreetName1,
      primaryStreetName2,
      primaryCity,
      primaryState,
      primaryZipCode
    );
    setValue("primaryPhysician.streetName", address);
    setValue("primaryPhysician.physicianAddress", address);

    if (getValues("orderingPhysician.physicianSameAsPrimary"))
      setValue("orderingPhysician.physicianAddress", address);
    setValue("orderingPhysician.streetName", address);
  }, [
    getValues("primaryPhysician"),
    getValues("orderingPhysician.physicianSameAsPrimary"),
  ]);

  // for orderingPhysician address change
  useEffect(() => {
    const orderingStreetName1 = getValues("orderingPhysician.streetName1");
    const orderingStreetName2 = getValues("orderingPhysician.streetName2");
    const orderingCity = getValues("orderingPhysician.city");
    const orderingState = getValues("orderingPhysician.physicianState");
    const orderingZipCode = getValues("orderingPhysician.zipCode");

    const address = formatAddress(
      orderingStreetName1,
      orderingStreetName2,
      orderingCity,
      orderingState,
      orderingZipCode
    );
    setValue("orderingPhysician.physicianAddress", address);
    setValue("orderingPhysician.streetName", address);
  }, [getValues("orderingPhysician")]);

  const handleOrderingPhysicianState = (
    name: string | undefined,
    value: any,
    setValue: UseFormSetValue<ReferralIntakePhysicianDetails>
  ) => {
    // for ordering
    const isPrimaryPhysician = getValues(
      "orderingPhysician.physicianSameAsPrimary"
    );

    if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.physicianName",
        value.primaryPhysician.physicianName
      )
    ) {
      const orderingPhysicianName = value.primaryPhysician.physicianName
        ? value.primaryPhysician.physicianName
        : "";
      setValue("orderingPhysician.physicianName", orderingPhysicianName);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.physicianNpiId",
        value.primaryPhysician.physicianNpiId
      )
    ) {
      const orderingNpiNumber = value.primaryPhysician.physicianNpiId
        ? value.primaryPhysician.physicianNpiId
        : "";
      setValue("orderingPhysician.physicianNpiId", orderingNpiNumber);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.taxId",
        value.primaryPhysician.taxId
      )
    ) {
      const orderingTaxId = value.primaryPhysician.taxId
        ? value.primaryPhysician.taxId
        : "";
      setValue("orderingPhysician.taxId", orderingTaxId);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.fax",
        value.primaryPhysician.fax
      )
    ) {
      const orderingFaxId = value.primaryPhysician.fax
        ? value.primaryPhysician.fax
        : "";
      setValue("orderingPhysician.fax", orderingFaxId);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.phoneNo",
        value.primaryPhysician.phoneNo
      )
    ) {
      const orderingPhone = value.primaryPhysician.phoneNo
        ? value.primaryPhysician.phoneNo
        : "";
      setValue("orderingPhysician.phoneNo", orderingPhone);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.phoneExt",
        value.primaryPhysician.phoneExt
      )
    ) {
      const orderingPhoneExt = value.primaryPhysician.phoneExt
        ? value.primaryPhysician.phoneExt
        : "";
      setValue("orderingPhysician.phoneExt", orderingPhoneExt);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.streetName1",
        value.primaryPhysician.streetName1
      )
    ) {
      const orderingStreetName1 = value.primaryPhysician.streetName1
        ? value.primaryPhysician.streetName1
        : "";
      setValue("orderingPhysician.streetName1", orderingStreetName1);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.streetName2",
        value.primaryPhysician.streetName2
      )
    ) {
      const orderingStreetName2 = value.primaryPhysician.streetName2
        ? value.primaryPhysician.streetName2
        : "";
      setValue("orderingPhysician.streetName2", orderingStreetName2);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.county",
        value.primaryPhysician.county
      )
    ) {
      const orderingCounty = value.primaryPhysician.county
        ? value.primaryPhysician.county
        : "";
      setValue("orderingPhysician.county", orderingCounty);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.city",
        value.primaryPhysician.city
      )
    ) {
      const orderingCity = value.primaryPhysician.city
        ? value.primaryPhysician.city
        : "";
      setValue("orderingPhysician.city", orderingCity);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.physicianState",
        value.primaryPhysician.physicianState
      )
    ) {
      const orderingState = value.primaryPhysician.physicianState
        ? value.primaryPhysician.physicianState
        : "";
      setValue("orderingPhysician.physicianState", orderingState);
    } else if (
      propertyCheck(
        isPrimaryPhysician,
        name,
        "primaryPhysician.zipCode",
        value.primaryPhysician.zipCode
      )
    ) {
      const orderingZipCode = value.primaryPhysician.zipCode
        ? value.primaryPhysician.zipCode
        : "";
      setValue("orderingPhysician.zipCode", orderingZipCode);
    }
  };

  const propertyCheck = (
    isPrimary: boolean,
    name: string | undefined,
    nameString: string,
    value: any
  ) => {
    return isPrimary && name === nameString && !isNil(value);
  };

  const getUpdatedOrderingPhysicianValue = () => {
    const primaryPhysicianName = getValues("primaryPhysician.physicianName");
    const primaryPhysicianId =
      getValues("primaryPhysician.physicianId") !== null
        ? getValues("primaryPhysician.physicianId")
        : 0;
    const primaryNpiNumber = getValues("primaryPhysician.physicianNpiId");
    const primaryTaxId = getValues("primaryPhysician.taxId");
    const primaryStreetName1 = getValues("primaryPhysician.streetName1");
    const primaryStreetName2 = getValues("primaryPhysician.streetName2");
    const primaryCity = getValues("primaryPhysician.city");
    const primaryCounty = getValues("primaryPhysician.county");
    const primaryState = getValues("primaryPhysician.physicianState");
    const primaryZipCode = getValues("primaryPhysician.zipCode");
    const primaryFax = getValues("primaryPhysician.fax");
    const primaryPhoneNumber = getValues("primaryPhysician.phoneNo");
    const primaryPhoneNumberExt = getValues("primaryPhysician.phoneExt");

    const address = formatAddress(
      primaryStreetName1,
      primaryStreetName2,
      primaryCity,
      primaryState,
      primaryZipCode
    );

    const orderingPhysician = getValues("orderingPhysician");

    reset({
      ...getValues(),
      orderingPhysician: {
        ...orderingPhysician,
        physicianId: primaryPhysicianId,
        physicianName: primaryPhysicianName,
        physicianNpiId: primaryNpiNumber,
        taxId: primaryTaxId,
        streetName: primaryStreetName1,
        streetName1: primaryStreetName1,
        streetName2: primaryStreetName2,
        city: primaryCity,
        county: primaryCounty,
        state: primaryState,
        zipCode: primaryZipCode,
        fax: primaryFax,
        physicianState: primaryState,
        phoneNo: primaryPhoneNumber,
        phoneExt: primaryPhoneNumberExt,
        physicianTypeId: PhysicianTypeID.ORDERING,
      },
    });
    const physicianDetails: PhysicianDetails = {
      physicianName: primaryPhysicianName,
      physicianNpiId: primaryNpiNumber,
      physicianId: primaryPhysicianId,
      streetName: primaryStreetName1,
      streetName1: primaryStreetName1,
      streetName2: primaryStreetName2,
      city: primaryCity,
      zipCode: primaryZipCode,
      taxId: primaryTaxId,
      phoneNo: primaryPhoneNumber,
      phoneExt: primaryPhoneNumberExt,
      address: address,
      physicianState: primaryState,
      fax: primaryFax,
      physicianTypeId: PhysicianTypeID.PRIMARY,
    };

    setPrimaryPhysician(physicianDetails);
    setOrderingPhysician(physicianDetails);
  };

  const triggerToggleUncheck = () => {
    setValue("orderingPhysician.physicianSameAsPrimary", false);
    if (toggleState) {
      setOrderingPhysician(undefined);
      reset({
        ...getValues(),
        orderingPhysician: {
          ...getValues("orderingPhysician"),
          physicianId: 0,
          physicianName: "",
          physicianNpiId: "",
          taxId: "",
          streetName: "",
          streetName1: "",
          streetName2: "",
          city: "",
          county: "",
          state: "",
          zipCode: "",
          fax: "",
          physicianState: "",
          physicianTypeId: PhysicianTypeID.ORDERING,
          phoneNo: "",
          phoneExt: "",
        },
      });
    }
    setToggleState(false);
  };

  const handleToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (event.target.checked) {
      if ("orderingPhysician.physicianSameAsPrimary" === value) {
        setValue("orderingPhysician.physicianSameAsPrimary", true);
        setToggleState(true);
        getUpdatedOrderingPhysicianValue();
      }
    } else {
      triggerToggleUncheck();
    }
  };

  const choosePrimaryPhysician = (option: PhysicianDetails) => {
    setPrimaryPhysician(option);
    if (!isNil(option)) {
      const {
        physicianName,
        physicianNpiId,
        taxId,
        city,
        physicianState,
        zipCode,
        streetName1,
        streetName2,
        fax,
        county,
        phoneExt,
        phoneNo,
      } = option;
      reset({
        ...getValues(),
        primaryPhysician: {
          ...getValues("primaryPhysician"),
          physicianId: getValue(primaryPhysician, "physicianId", 0),
          physicianName: physicianName,
          physicianNpiId: physicianNpiId,
          taxId: taxId,
          streetName1: streetName1,
          streetName2: streetName2,
          city: city,
          county: county,
          state: physicianState,
          physicianState: physicianState,
          zipCode: zipCode,
          fax: getFormattedPhoneNo(fax),
          physicianTypeId: PhysicianTypeID.PRIMARY,
          phoneExt: phoneExt,
          phoneNo: phoneNo ? getFormattedPhoneNo(phoneNo) : "",
        },
      });
    }
    setAddPrimaryPhysicianDetailsManually(false);

    if (toggleState) {
      getUpdatedOrderingPhysicianValue();
    }
  };

  const chooseOrderingPhysician = (option: any) => {
    setOrderingPhysician(option);
    if (!isNil(option)) {
      const {
        physicianName,
        physicianNpiId,
        taxId,
        city,
        physicianState,
        zipCode,
        streetName1,
        streetName2,
        fax,
        county,
        phoneExt,
        phoneNo,
      } = option;
      reset({
        ...getValues(),
        orderingPhysician: {
          ...getValues("orderingPhysician"),
          physicianId: getValue(orderingPhysician, "physicianId", 0),
          physicianName: physicianName,
          physicianNpiId: physicianNpiId,
          taxId: taxId,
          streetName1: streetName1,
          streetName2: streetName2,
          city: city,
          county: county,
          state: physicianState,
          zipCode: zipCode,
          fax: getFormattedPhoneNo(fax),
          physicianState: physicianState,
          physicianTypeId: PhysicianTypeID.ORDERING,
          phoneExt: phoneExt,
          phoneNo: phoneNo ? getFormattedPhoneNo(phoneNo) : "",
        },
      });
    }
    setAddOrderingPhysicianDetailsManually(false);
  };

  const handleAddPrimaryPhysicianManually = () => {
    setAddPrimaryPhysicianDetailsManually(true);
    setToggleState(false);
    triggerToggleUncheck();
    reset({
      ...getValues(),
      primaryPhysician: {
        ...getValues("primaryPhysician"),
        physicianId: 0,
        physicianName: "",
        physicianNpiId: "",
        taxId: "",
        streetName: "",
        streetName1: "",
        streetName2: "",
        city: "",
        county: "",
        state: "",
        zipCode: "",
        physicianState: "",
        fax: "",
        physicianTypeId: PhysicianTypeID.PRIMARY,
        phoneNo: "",
        phoneExt: "",
      },
    });
    setPrimaryPhysician(undefined);
    setNoValidate(false);
  };

  const handelOrderingPhysicianAddManually = () => {
    const orderingPhysicianDetailsManually =
      addOrderingPhysicianDetailsManually;
    setAddOrderingPhysicianDetailsManually(!orderingPhysicianDetailsManually);

    setOrderingPhysician(undefined);
    setNoValidate(false);
    reset({
      ...getValues(),
      orderingPhysician: {
        ...getValues("orderingPhysician"),
        physicianId: 0,
        physicianName: "",
        physicianNpiId: "",
        taxId: "",
        streetName: "",
        streetName1: "",
        streetName2: "",
        city: "",
        county: "",
        state: "",
        zipCode: "",
        fax: "",
        physicianState: "",
        physicianTypeId: PhysicianTypeID.ORDERING,
        physicianSameAsPrimary: false,
        phoneNo: "",
        phoneExt: "",
      },
    });
  };

  const TypographyBox = (
    variant:
      | OverridableStringUnion<
          "inherit" | Variant,
          TypographyPropsVariantOverrides
        >
      | undefined,
    value: string,
    color: string,
    fontWeight: string,
    sx?: object
  ) => {
    return (
      <Typography
        variant={variant}
        fontWeight={fontWeight}
        color={color}
        sx={sx}
      >
        {value}
      </Typography>
    );
  };

  const orderingPhysicianFormNames = {
    physicianName: "orderingPhysician.physicianName",
    physicianNpiId: "orderingPhysician.physicianNpiId",
    phoneNo: "orderingPhysician.phoneNo",
    phoneExt: "orderingPhysician.phoneExt",
    taxId: "orderingPhysician.taxId",
    address: [
      "orderingPhysician.streetName1",
      "orderingPhysician.streetName2",
      "orderingPhysician.city",
      "orderingPhysician.county",
      "orderingPhysician.physicianState",
      "orderingPhysician.zipCode",
    ],
    fax: "orderingPhysician.fax",
  };

  const watchedPrimaryPhysicianFields = useWatch({
    name: "primaryPhysician",
    control,
  });

  useEffect(() => {
    if(!isEmptyValue(getFormValues(fieldNames, getValues))) {
      setNoValidate(false);
    }
  }, [watchedPrimaryPhysicianFields]);

  return (
    <>
      <Grid container sx={MainContainer}>
        {/* Primary Physician */}
        <Grid item xs={12} pt={"1rem"}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={Header}>
                <Grid item xs={12}>
                  <Box sx={Title}>
                    {TypographyBox(
                      "h6",
                      "PRIMARY PHYSICIAN DETAILS:",
                      colors.fonts[20],
                      fontWeight.Weight[5]
                    )}
                  </Box>
                  <Box sx={Subtitle}>
                    {TypographyBox(
                      "body1",
                      "Please add physician details and continue:",
                      colors.fonts[5],
                      fontWeight.Weight[3]
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={Body}>
              <PhysicianInputForm
                // useForm hook methods
                control={control}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                // assign selected state and form names
                physician={primaryPhysician || null}
                names={primaryPhysicianFormNames}
                // assign handlers
                choosePhysician={choosePrimaryPhysician}
                addManually={addPrimaryPhysicianDetailsManually}
                handleAddManually={handleAddPrimaryPhysicianManually}
                open={openPrimaryPhysician}
                setOpen={setOpenPrimaryPhysician}
                setOpenAutocomplete={setOpenPrimaryPhysicianAutocomplete}
                openAutocomplete={openPrimaryPhysicianAutocomplete}
                name="primaryPhysicianPhysicianName"
                hideAddManually={NppesAddManuallyTypes.SHOW_HIDE_MANUALLY_SIDE}
                noValidate={noValidate}
                // assign value for autoComplete
              />
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        {/* Ordering Physician */}
        <Grid item xs={12} pt={"0.5rem"}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={Header}>
                <Grid item xs={2.5}>
                  <Box sx={Title}>
                    {TypographyBox(
                      "h6",
                      "ORDERING PHYSICIAN DETAILS:",
                      colors.fonts[20],
                      fontWeight.Weight[5]
                    )}
                  </Box>
                  <Box sx={Subtitle}>
                    {TypographyBox(
                      "body1",
                      "Please add physician details and continue:",
                      colors.fonts[5],
                      fontWeight.Weight[3]
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={Title}></Box>
                  <Box>
                    <Stack
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      sx={Pad}
                    >
                      <Switch
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={(e: any) =>
                          handleToggle(
                            e,
                            "orderingPhysician.physicianSameAsPrimary"
                          )
                        }
                        checked={toggleState}
                      />
                      {TypographyBox(
                        "subtitle2",
                        "Same as primary physician",
                        colors.fonts[4],
                        fontWeight.Weight[6]
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={Body}>
              <PhysicianInputForm
                // useForm hook methods
                control={control}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                // assign selected primary state and form names
                physician={orderingPhysician || null}
                names={orderingPhysicianFormNames}
                // assign handlers
                choosePhysician={chooseOrderingPhysician}
                addManually={addOrderingPhysicianDetailsManually}
                handleAddManually={handelOrderingPhysicianAddManually}
                open={openOrderingPhysician}
                setOpen={setOpenOrderingPhysician}
                openAutocomplete={openOrderingPhysicianAutocomplete}
                setOpenAutocomplete={setOpenOrderingPhysicianAutocomplete}
                name="orderingPhysicianPhysicianName"
                hideAddManually={NppesAddManuallyTypes.SHOW_HIDE_MANUALLY_SIDE} // assign value for autoComplete
                isReadOnly={toggleState}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderingPhysician;
