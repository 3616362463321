import { Box, Divider, Typography, Button, Grid } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { debounce, isEmpty } from "lodash";
import dayjs from "dayjs";

import Header from "../../../containers/Header";
import {
  DividerLine,
  HeaderModuleName,
  HeaderStyling,
  InputCloseIcon,
  PatientBox,
  PatientContainerBox,
  PatientDetailBox,
  SelectedOption,
} from "../../../styles/style";
import PatientFilter from "../containers/Filter";
import SearchRecord from "./SearchRecords";
import {
  CheckEligibility,
  PatientFilterOptions,
  PatientRecordSearch,
  PatientSearch,
  PatientSearchPayload,
} from "../../../../../models/Patient";
import {
  AllPatientRecordResponse,
  CheckEligibilityResponse,
} from "../../../../../models/Api/AllPatientRecord";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors } from "../../../../../styles/colors";
import ValidateRecord from "./ValidateRecord";
import { EligibilityRecord } from "../../../../../models/Master";
import { DateFormats } from "../../../../../constants/Date";
import { EligibilityCheckDispatchTypes } from "../../../../../constants/AllPatientRecord";
import {
  areObjectsEqual,
  getHealthPlanValueType,
  getValue,
} from "../../../../../utils";
import { HealthPlanData } from "../../../../../constants/PatientList";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { formatDate } from "../../../../../utils/DateTime";
import {
  defaultFormValues,
  PatientSearchRecordFields,
} from "../../../../../constants/Patient";
import { EligibilityRecordResponse } from "../../../../../models/Api/Master";
import { EligibilityData } from "../../../../../models/EligibilityData";

export interface PropsFromState {
  allPatientRecord: AllPatientRecordResponse;
  checkEligibilityData: CheckEligibilityResponse;
  eligibilityRecord: EligibilityRecord;
  payerState: PatientFilterOptions[];
  eligibilityRecordState: EligibilityRecordResponse;
}

export interface PropsFromDispatch {
  checkEligibility: (payload: any) => void;
  getAllPatientRecord: (payload?: PatientSearch) => void;
  getEligibilityData: (benefitPlanUid: string) => void;
  resetStates: (actionType: string[]) => void;
  storeEligibilityData: (data: EligibilityData) => void;
}

type Props = PropsFromState & PropsFromDispatch;

const Main: React.FC<Props> = ({
  allPatientRecord,
  checkEligibility,
  checkEligibilityData,
  eligibilityRecord,
  getAllPatientRecord,
  getEligibilityData,
  payerState,
  resetStates,
  storeEligibilityData,
}: Props) => {
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [patientRecord, setPatientRecord] =
    useState<PatientRecordSearch | null>(null);
  const [search, setSearch] = useState(false);
  const [type, setType] = useState("");
  const [increment, setIncrement] = useState(1);
  const [eligibilityCheck, setCheckEligibility] =
    useState<CheckEligibility | null>(null);
  const [validate, setValidate] = useState(false);
  const [eligibilityDataSet, setEligibilityData] =
    useState<EligibilityRecord>();
  const [checkEligibilityBtnFlag, setCheckEligibilityBtnFlag] =
    useState<boolean>(true);
  const [healthPlan, setHealthPlan] =
    useState<PatientFilterOptions>(HealthPlanData);

  const { response } = allPatientRecord;
  const {
    response: checkEligibilityResponse,
    error: checkEligibilityError,
    loading: checkEligibilityLoading,
  } = checkEligibilityData;
  const [selectedHealthPlan, setSelectedHealthPlan] = useState<string>("");

  const handleClear = () => {
    setType("search");
    setTimeout(() => {
      unregister([
        "firstName",
        "lastName",
        "authorizationNumber",
        "dateOfBirth",
        "payerId",
        "subscriberId",
        "healthPlan",
        "policyId",
      ]);
    }, 10);
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("authorizationNumber", "");
    setValue("dateOfBirth", null);
    setValue("subscriberId", "");
    setValue("healthPlan", "");
    setShowSelected(false);
    setSearch(false);
    setValidate(false);
    setPatientRecord(null);
    setIncrement(increment + 1);
    handleSearchValidate("search");
    setHealthPlan(HealthPlanData);
  };

  const methods = useForm<any>({
    defaultValues: defaultFormValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    unregister,
    reset,
    clearErrors,
    watch,
    formState,
    formState: { errors },
  } = methods;

  const watchedFields = watch(PatientSearchRecordFields);
  const initialValues = useRef(getValues());

  useEffect(() => {
    if (!search && !validate) {
      handleSearchValidate("search");
    }
    if (search) {
      setPatientRecord(response || null);
    }
    if (validate) {
      setPatientRecord(null);
    }
  }, [response, search, validate]);

  useEffect(() => {
    if (
      checkEligibilityResponse &&
      !checkEligibilityLoading &&
      !isEmpty(
        getValue(checkEligibilityResponse, "additionalNotes.benefitPlanUid", "")
      ) &&
      getValue(checkEligibilityResponse, "additionalNotes.benefitPlanUid", "")
        .length > 0
    ) {
      setCheckEligibility(checkEligibilityResponse || null);
      getEligibilityData(
        checkEligibilityResponse.additionalNotes.benefitPlanUid
      );
    }
  }, [checkEligibilityLoading]);

  useEffect(() => {
    if (eligibilityRecord) {
      setEligibilityData(eligibilityRecord);
    }
  }, [eligibilityRecord]);

  useEffect(() => {
    if (checkEligibilityError) {
      setValidate(true);
      setSearch(false);
      setCheckEligibility(null);
    }
  }, [checkEligibilityError]);

  useEffect(() => {
    if (areObjectsEqual(defaultFormValues, values)) {
      setCheckEligibilityBtnFlag(true);
    }
    return () => {
      resetStates([EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET]);
    };
  }, []);

  const values = getValues();

  const handleSave = (data: PatientSearchPayload) => {
    const searchPayload = { ...data } as Partial<PatientSearchPayload>;
    delete searchPayload["subscriberId"];
    delete searchPayload["healthPlan"];
    getAllPatientRecord(searchPayload as PatientSearch);
  };

  const handleSearchValidate = (clickType: string) => {
    if ("search" === clickType) {
      const formValues = getValues();

      const dateOfBirth = getValue(formValues, "dateOfBirth")
        ? formatDate(formValues.dateOfBirth, DateFormats.MM_DD_YYYY)
        : "";

      setShowSelected(true);
      setSearch(true);
      setValidate(false);
      unregister([
        "lastName",
        "authorizationNumber",
        "dateOfBirth",
        "payerId",
        "policyId",
      ]);
      reset({ ...getValues(), ...formValues, dateOfBirth });
      resetStates([EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET]);
      handleSubmit(handleSave)();
      setCheckEligibilityBtnFlag(false);
    } else {
      setSearch(false);
      setCheckEligibility(null);
      setValidate(true);
      handleSubmit(handleCheckEligibility)();
    }
  };

  const handleCheckEligibility = () => {
    const values = getValues();
    const payload = {
      payerId:
        values && values.healthPlan
          ? getHealthPlanValueType(values.healthPlan, 0)
          : "",
      policyId: values && values.subscriberId ? values.subscriberId : "",
      payerName:
        values && values.healthPlan
          ? getHealthPlanValueType(values.healthPlan, 1)
          : "",
      lastName: values && values.lastName ? values.lastName : "",
      dob: getValue(values, "dateOfBirth")
        ? formatDate(values.dateOfBirth, DateFormats.YYYY_MM_DD)
        : "",
    };
    checkEligibility(payload);
  };

  const handleClearField = (name: string) => {
    setValue(name, "");
    setHealthPlan(HealthPlanData);
    handleSearchValidate("search");
  };

  useEffect(() => {
    if (
      errors &&
      errors?.dateOfBirth &&
      errors?.dateOfBirth?.message === undefined
    ) {
      unregister(["dateOfBirth"])
      setCheckEligibilityBtnFlag(true);
    } else {
      if (
        formState.isDirty &&
        Object.keys(errors).includes("dateOfBirth") === false
      ) {
        debounce(() => {
          const hasChange = watchedFields.some(
            (field, index) =>
              field !==
              initialValues.current[Object.keys(initialValues.current)[index]]
          );
          setCheckEligibilityBtnFlag(hasChange);
        }, 500)();
      }
    }
    if (values && values.healthPlan != selectedHealthPlan) {
      setCheckEligibilityBtnFlag(true);
      setSelectedHealthPlan(values.healthPlan);
    }
  }, [formState.isDirty, watchedFields]);

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      setCheckEligibilityBtnFlag(hasErrors);
    }
  }, [Object.keys(errors)]);

  return (
    <>
      <Header />
      <PatientContainerBox>
        <PatientBox>
          <PatientFilter
            values={values}
            patientRecord={patientRecord}
            control={control}
            setValue={setValue}
            handleSearchValidate={handleSearchValidate}
            setType={setType}
            type={type}
            healthPlan={healthPlan}
            setHealthPlan={setHealthPlan}
            clearErrors={clearErrors}
            checkEligibilityBtnFlag={checkEligibilityBtnFlag}
          />
        </PatientBox>
        <PatientDetailBox>
          <Grid container sx={HeaderStyling}>
            <Grid item xs={2.3} sx={displayFlex}>
              <Box>
                <Typography
                  variant="h4"
                  fontWeight={fontWeight.Weight[6]}
                  sx={HeaderModuleName}
                  color={colors.black[3]}
                >
                  RECORDS:
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[1]}
                  color={colors.fonts[11]}
                >
                  List of patient records modified recently.
                </Typography>
              </Box>
              {(values.firstName ||
                values.lastName ||
                values.dateOfBirth ||
                values.healthPlan ||
                values.subscriberId) &&
                showSelected && (
                  <Divider orientation="vertical" flexItem sx={DividerLine} />
                )}
            </Grid>
            <Grid item xs={9.5}>
              <Grid container>
                {values && values.firstName && showSelected && (
                  <FieldChipRenderer
                    value={values.firstName}
                    fieldName="firstName"
                    handleClearField={handleClearField}
                  />
                )}
                {values && values.lastName && showSelected && (
                  <FieldChipRenderer
                    value={values.lastName}
                    fieldName="lastName"
                    handleClearField={handleClearField}
                  />
                )}
                {values && values.authorizationNumber && showSelected && (
                  <FieldChipRenderer
                    value={values.authorizationNumber}
                    fieldName="authorizationNumber"
                    handleClearField={handleClearField}
                  />
                )}
                {values &&
                  values.dateOfBirth &&
                  dayjs(values.dateOfBirth).isValid() &&
                  showSelected && (
                    <FieldChipRenderer
                      value={`${new Date(values.dateOfBirth).getMonth() + 1}/
                       ${new Date(values.dateOfBirth).getDate()}/
                       ${new Date(values.dateOfBirth).getFullYear()}`}
                      fieldName="dateOfBirth"
                      handleClearField={handleClearField}
                    />
                  )}
                {values && values.subscriberId && showSelected && (
                  <FieldChipRenderer
                    value={values.subscriberId}
                    fieldName="subscriberId"
                    handleClearField={handleClearField}
                  />
                )}
                {values && values.healthPlan && showSelected && (
                  <FieldChipRenderer
                    value={getHealthPlanValueType(values.healthPlan, 1)}
                    fieldName="healthPlan"
                    handleClearField={handleClearField}
                  />
                )}
                {(values.firstName ||
                  values.lastName ||
                  values.authorizationNumber ||
                  values.healthPlan ||
                  (values.dateOfBirth && dayjs(values.dateOfBirth).isValid()) ||
                  values.subscriberId) &&
                  showSelected && (
                    <Grid item xs={"auto"}>
                      <Box ml={"1.5rem"} pt={"0.2rem"}>
                        <Button variant="outlined" onClick={handleClear}>
                          CLEAR
                        </Button>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>

          {validate ? (
            <ValidateRecord
              checkEligibility={eligibilityCheck}
              fieldValues={getValues()}
              CheckEligibilityData={checkEligibilityData}
              eligibilityRecord={eligibilityDataSet}
              payerState={payerState}
              resetStates={resetStates}
              storeEligibilityData={storeEligibilityData}
            />
          ) : (
            <SearchRecord
              patientRecord={patientRecord ? patientRecord : null}
              setValue={setValue}
              getAllPatientRecord={getAllPatientRecord}
              fieldValues={getValues()}
            />
          )}
        </PatientDetailBox>
      </PatientContainerBox>
    </>
  );
};

type FieldChipRendererProps = {
  value: string;
  handleClearField: (name: string) => void;
  fieldName: string;
};

const FieldChipRenderer = ({
  value,
  handleClearField,
  fieldName,
}: FieldChipRendererProps) => {
  return (
    <Grid item xs={"auto"}>
      <Box sx={SelectedOption}>
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[6]}
          color={colors.fonts[18]}
        >
          {value}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[6]}
          color={colors.fonts[18]}
          ml={"0.5rem"}
        >
          <IoIosClose
            size="20"
            style={InputCloseIcon}
            onClick={() => handleClearField(fieldName)}
          />
        </Typography>
      </Box>
    </Grid>
  );
};

export default Main;
