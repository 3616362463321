import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "lodash";

import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  DetailBox,
  DividerLine,
  ReferralGrid,
  BackButton,
} from "../styles/style";
import {
  displayFlex,
  flexAlignCentre,
  flexAllEnd,
} from "../../../../../styles/mui/styles/display";
import PatientTab from "../containers/PatientTab";
import {
  PatientRequestResponse,
  SavePatientRequestResponse,
  Icd10RecordResponse,
  ReferralSubmitResponse,
  SaveIcd10RecordResponse,
  AllPatientIntakeTabResponse,
  ReferralSubmitPayload,
} from "../../../../../models/Api/AllPatientRecord";
import {
  PostServiceProviderResponse,
  PostServiceRequestResponse,
  ServiceRequestResponse,
} from "../../../../../models/Api/Service";
import {
  PatientRequest,
  NotifyInternalData,
} from "../../../../../models/Patient";
import {
  PhysicianDetailsResponse,
  PhysicianRecordsResponse,
} from "../../../../../models/Api/PhysicianDetails";
import { DocumentReviewResponse } from "../../../../../models/Api/Document";
import {
  OrderingPhysicianInitialState,
  PatientInitialRequest,
  Icd10RecordsDefaultValues,
  documentDefaultValues,
  DocumentFlowType,
  PatientDetailType,
  SaveOrUpdatePatientRequestDispatchTypes,
  ReferralSubmitDispatchTypes,
  Icd10RecordsActionDispatchTypes,
  PatientRequestDispatchTypes,
  IntakeType,
} from "../../../../../constants/AllPatientRecord";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import IncompleteDialog from "./IncompleteDialog";
import {
  ServiceProviderActionDispatchType,
  ServiceProviderDefaultValues,
  ServiceRequestActionDispatchType,
  ServiceRequestInitialValues,
} from "../../../../../constants/Service";
import { ServiceProviderDataResponse } from "../../../../../models/Api/Service";
import ErrorDialog from "./ErrorDialog";
import { UserDetailsPayload } from "../../../../../models/User";
import {
  getAtob,
  getConcatStringOrNull,
  getValue,
  scrollTop,
} from "../../../../../utils";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { rules } from "../../../../../utils/validation/Validation";
import { matchState } from "../../../../../utils/StateSearch";
import {
  DocumentTypeResponse,
  PriorAuthReferralSourceMasterResponse,
} from "../../../../../models/Api/Master";
import AlertDialog from "./AlertDialog";
import {
  BackClickAlertMessage,
  InjectedTo,
  PatientInformationActionDispatchTypesView,
} from "../../../../../constants/Patient";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import Permission from "../../../../Permission";
import {
  GetPhysicianDetailsActionDispatchTypes,
  PhysicianTypeID,
  PostPhysicianDetailsActionDispatchTypes,
} from "../../../../../constants/PhysicianDetails";
import { DocumentReviewActionDispatchTypes } from "../../../../../constants/Document";
import usePreviousDataState from "../../../../../hooks/usePreviousDataState";
import PatientHeader from "../../../components/ActiveAuthCommon/PatientHeader/containers";
import { NotifyInternalDataType } from "../../../../../constants/Constants";
import { MasterEndPointValues } from "../../../../../constants/MasterValues";

export interface PropsFromState {
  patientHeaderData: PatientInformationViewResponse;
  patientRequestData: PatientRequestResponse;
  icd10Records: Icd10RecordResponse;
  saveIcd10RecordsRsp: SaveIcd10RecordResponse;
  saveUpdatePatientData: SavePatientRequestResponse;
  serviceRequestData: ServiceRequestResponse;
  referralIntakePhysicianState: PhysicianDetailsResponse;
  physicianRecordsState: PhysicianRecordsResponse;
  referralSubmitData: ReferralSubmitResponse;
  documentReview: DocumentReviewResponse;
  serviceProviderData: ServiceProviderDataResponse;
  serviceProviderUpdateData: PostServiceProviderResponse;
  serviceRequestUpdateData: PostServiceRequestResponse;
  referralDocumentUpdateData: DocumentReviewResponse;
  tabList: AllPatientIntakeTabResponse;
  user: UserDetailsPayload;
  documentTypeResponse: DocumentTypeResponse;
  priorAuthReferralSource: PriorAuthReferralSourceMasterResponse;
  updatedPatientHeaderResp: PatientInformationViewResponse;
}

export interface PropsFromDispatch {
  getPatient: (patientId: number, type: number) => void;
  getIcd10RecordDetails: (id: number, typeId: number) => void;
  getServiceRequest: (referralId: number) => void;
  getPatientRequestById: (referralId: string, patientId: string) => void;
  getServiceProviderById: (referralId: string) => void;
  updateReferralDetail: (payload: any, activeTab: number) => void;
  getPhysicianDetails: (referralId: string) => void;
  saveReferralDetail: (payload: any, activeTab: number) => void;
  postReferralSubmit: (
    payload: ReferralSubmitPayload,
    referralIntakeDetails?: boolean
  ) => void;
  getDocumentReviewData: (id: string, type: number) => void;
  resetReferralIntake: () => void;
  resetPhysicianRecords: () => void;
  getDocumentType: () => void;
  resetStates: (actionType: string[]) => void;
  notifyInternalDataUpdates: (payload: NotifyInternalData) => void;
  getPriorAuthReferralSourceMasterResp: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const ReferralIntake: React.FC<AllProps> = ({
  patientHeaderData,
  icd10Records,
  getPatient,
  patientRequestData,
  serviceRequestData,
  getPatientRequestById,
  getIcd10RecordDetails,
  getServiceRequest,
  updateReferralDetail,
  referralIntakePhysicianState,
  getPhysicianDetails,
  saveReferralDetail,
  saveUpdatePatientData,
  referralSubmitData,
  postReferralSubmit,
  documentReview,
  getDocumentReviewData,
  serviceProviderData,
  getServiceProviderById,
  serviceProviderUpdateData,
  serviceRequestUpdateData,
  referralDocumentUpdateData,
  tabList,
  user,
  resetReferralIntake,
  resetPhysicianRecords,
  getDocumentType,
  documentTypeResponse,
  resetStates,
  notifyInternalDataUpdates,
  priorAuthReferralSource,
  getPriorAuthReferralSourceMasterResp,
  updatedPatientHeaderResp,
}: AllProps) => {
  const navigate = useNavigate();
  const { patientId, referralId, additionalParams } = useParams();

  const { loading: priorAuthReferralSourceLoading } = priorAuthReferralSource;
  const { loading, response } = patientHeaderData;
  const { loading: patientRequestLoading, response: patientRequestResponse } =
    patientRequestData;

  const [patientRequestDetails, setPatientRequestDetails] = useState<any>();
  const { loading: icd10RecordsLoading, response: Icd10RecordsResponse } =
    icd10Records;

  const { loading: documentLoading, response: documentResponse } =
    documentReview;
  const { loading: loadingDocumentType, response: documentType } =
    documentTypeResponse;

  const { response: physicianDetailsResp } = referralIntakePhysicianState;
  const { loading: serviceRequestLoading, response: serviceRequestResponse } =
    serviceRequestData;
  const { loading: serviceProviderLoading, response: serviceProviderResponse } =
    serviceProviderData;

  const { response: updatedPatientHeaderResponse } = updatedPatientHeaderResp;

  const [patientRequestDetail, setPatientRequestDetail] = useState<
    PatientRequest | any
  >(null);
  const [icd10Response, setICd10Response] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<number>(-1);

  const [localReferralId, setReferralId] = useState<string>("");
  const { response: referralSubmitResponse } = referralSubmitData;
  const [isCompleted, setIsCompleted] = useState(true);
  const [formError, setFormError] = useState<any>();
  const [hasFormError, setHasFormError] = useState(false);
  const [tabInfo, setTabInfo] = useState<any>();
  const [openAlert, setOpenAlert] = useState(false);
  const [backClick, setBackClick] = useState(false);
  const [disableRedirect, setdisableRedirect] = useState(false);

  const [authTypeId, setAuthTypeId] = useState<number>(1);

  // saveDetailsClickType === 0 -> default value
  // saveDetailsClickType === 1 -> save details button clicked -> do not navigate
  // saveDetailsClickType === 2 -> save and exit button clicked -> navigate
  const [saveDetailsClickType, setSaveDetailsClickType] = useState<number>(0);

  const Params = additionalParams != undefined ? additionalParams : "";
  useEffect(() => {
    if (response && response.patientId > 0) {
      const intakeStageId =
        getValue(getAtob(Params), "intakeStageId") !== 0 &&
        !isEmpty(getAtob(Params))
          ? getValue(getAtob(Params), "intakeStageId")
          : getValue(response, "intakeStageId");

          if (!disableRedirect) {
            if (intakeStageId > 0) {
              setCurrentTab(intakeStageId - 1);
            } else if (intakeStageId === 0) {
              setCurrentTab(0);
            }
          }
      localAuthType(getValue(response, "authorizationTypeId", MasterEndPointValues.HomeHealthIntermittent));
    }
  }, [response]);

  const localAuthType = (authTypeId: number) => {
    setAuthTypeId(authTypeId);
  };

  useEffect(() => {
    if (!priorAuthReferralSourceLoading) {
      getPriorAuthReferralSourceMasterResp();
    }
  }, []);

  // navigate if referral is completed
  useEffect(() => {
    if (
      referralSubmitResponse &&
      referralSubmitResponse.isCompleted &&
      !hasFormError
    ) {
      setIsCompleted(true);
      if (saveDetailsClickType === 2) {
        navigateToAuthDetails();
      }
    } else if (
      referralSubmitResponse &&
      referralSubmitResponse.isCompleted != null &&
      !referralSubmitResponse.isCompleted
    ) {
      if (saveDetailsClickType === 2) {
        navigateToAuthDetails();
      }
      setIsCompleted(false);
    }
  }, [referralSubmitResponse]);

  useEffect(() => {
    findCurrentTab(currentTab);
  }, [currentTab]);

  const handlePatientDetails = (
    referralId: string | undefined,
    patientId: string | undefined,
    loading: boolean
  ) => {
    const numericReferralId = Number(referralId);
    const numericPatientId = Number(patientId);
    if (numericReferralId > 0) {
      setReferralId(referralId ? referralId.toString() : "");
      if (!loading) {
        getPatient(numericReferralId, PatientDetailType.Referral);
      }
    } else if (numericPatientId > 0) {
      if (!loading) {
        getPatient(numericPatientId, PatientDetailType.Patient);
      }
    }
  };

  useEffect(() => {
    if (updatedPatientHeaderResponse) {
      if (!patientRequestLoading && (referralId || patientId)) {
        if (Number(referralId) !== 0) {
          getPatientRequestById(referralId ? referralId : "", "0");
        } else if (Number(patientId) !== 0 && Number(referralId) === 0) {
          getPatientRequestById("0", patientId ? patientId : "");
        }
      }
      if (referralId && currentTab === 2) {
        getPhysicianDetails(referralId);
      }
      handlePatientDetails(referralId, patientId, loading);
    }
  }, [updatedPatientHeaderResponse]);

  const findCurrentTab = (currentTabInfo: any) => {
    switch (currentTabInfo) {
      case 0:
        setTabInfo(PatientInitialRequest);
        break;
      case 1:
        setTabInfo(Icd10RecordsDefaultValues);
        break;
      case 2:
        setTabInfo(OrderingPhysicianInitialState);
        break;
      case 3:
        setTabInfo(ServiceProviderDefaultValues);
        break;
      case 4:
        setTabInfo(ServiceRequestInitialValues);
        break;
      case 5:
        setTabInfo(documentDefaultValues);
        break;
      default:
        return {};
    }
  };

  const methods = useForm<any>({
    defaultValues: tabInfo,
    mode: "all",
  });

  const {
    getValues,
    setValue,
    control,
    reset,
    handleSubmit,
    clearErrors,
    watch,
    trigger,
    formState,
    resetField,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emergencyContact",
  });

  const {
    fields: icd10RecordsFields,
    append: icd10RecordsAppend,
    remove: icd10RecordsRemove,
  } = useFieldArray({
    control,
    name: "icdCodesInfoDto",
    rules: currentTab === 1 ? rules.icdCodes : {},
  });

  const values = getValues();

  const previousState = usePreviousDataState(
    saveUpdatePatientData.response,
    patientRequestData.response
  );

  const hasStateChanged = useMemo(() => {
    if (
      previousState.ref1 != saveUpdatePatientData.response &&
      getValue(saveUpdatePatientData, "response.referralDetail.referralId") != 0
    ) {
      return 0;
    }
    if (previousState.ref2 != patientRequestResponse) {
      return 1;
    }
  }, [previousState, saveUpdatePatientData, patientRequestData]);

  useEffect(() => {
    if (hasStateChanged === 0) {
      setPatientRequestDetails(saveUpdatePatientData.response);
    } else if (hasStateChanged === 1) {
      setPatientRequestDetails(patientRequestData.response);
    }
  }, [hasStateChanged]);

  useEffect(() => {
    scrollTop();
    setReferralId(referralId ? referralId : "");
    handlePatientDetails(referralId, patientId, loading);
    return () => {
      resetStates([
        SaveOrUpdatePatientRequestDispatchTypes.SAVE_PATIENT_REQUEST_RESET,
        ReferralSubmitDispatchTypes.REFERRAL_SUBMIT_RESET,
        PatientInformationActionDispatchTypesView.VIEW_PATIENT_INFORMATION_RESET,
        Icd10RecordsActionDispatchTypes.ICD_10_RESET,
        PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_RESET,
        GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_RESET,
        ServiceProviderActionDispatchType.SERVICE_PROVIDER_DETAIL_RESET,
        ServiceRequestActionDispatchType.SERVICE_REQUEST_RESET,
        DocumentReviewActionDispatchTypes.DOCUMENT_REVIEW_RESET,
        PatientRequestDispatchTypes.PATIENT_REQUEST_RESET,
        ServiceProviderActionDispatchType.SERVICE_PROVIDER_POST_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        if (!patientRequestLoading && (referralId || patientId)) {
          if (Number(referralId) !== 0) {
            getPatientRequestById(referralId ? referralId : "", "0");
          } else if (Number(patientId) !== 0 && Number(referralId) === 0) {
            getPatientRequestById("0", patientId ? patientId : "");
          }
        }
        break;
      case 1:
        if (!icd10RecordsLoading && referralId) {
          getIcd10RecordDetails(Number(referralId), DocumentFlowType.Auth);
        }
        break;
      case 2:
        if (referralId) {
          getPhysicianDetails(referralId);
        }
        break;
      case 3:
        if (!serviceProviderLoading && referralId) {
          getServiceProviderById(referralId);
        }
        break;
      case 4:
        if (!serviceRequestLoading && referralId) {
          getServiceRequest(Number(referralId));
        }
        break;
      case 5:
        if (!documentLoading && referralId) {
          getDocumentReviewData(referralId, DocumentFlowType.Auth);
          if (!loadingDocumentType) {
            getDocumentType();
          }
        }
        break;
      default:
        break;
    }
  }, [currentTab, referralId, patientId]);

  useEffect(() => {
    switch (currentTab) {
      case 0:
        if (patientRequestDetails) {
          setTimeout(() => {
            reset(patientRequestDetails);
            setPatientRequestDetail(patientRequestDetails);
          }, 100);
        } else {
          reset(PatientInitialRequest);
          setPatientRequestDetail(PatientInitialRequest);
        }
        break;
      case 1:
        if (Icd10RecordsResponse) {
          reset(Icd10RecordsResponse);
          setICd10Response(Icd10RecordsResponse);
        } else {
          reset(Icd10RecordsDefaultValues);
        }
        break;
      case 2:
        if (physicianDetailsResp) {
          reset(physicianDetailsResp);
        } else {
          reset(OrderingPhysicianInitialState);
        }
        break;
      case 3:
        if (serviceProviderResponse) {
          const phone = getValue(serviceProviderResponse, "phone") || "";
          const extension =
            getValue(serviceProviderResponse, "extension") || "";
          reset({
            ...serviceProviderResponse,
            phone,
            extension,
          });
        } else {
          reset(ServiceProviderDefaultValues);
        }
        break;
      case 4:
        if (serviceRequestResponse) {
          reset(serviceRequestResponse);
        } else {
          reset(ServiceRequestInitialValues);
        }
        break;
      case 5:
        if (documentResponse) {
          reset(documentResponse);
        } else {
          reset(documentDefaultValues);
        }
        break;
      default:
        break;
    }
  }, [
    currentTab,
    patientRequestDetails,
    Icd10RecordsResponse,
    physicianDetailsResp,
    serviceProviderResponse,
    serviceRequestResponse,
    documentResponse,
    saveDetailsClickType,
  ]);

  useEffect(() => {
    const localPrimaryPhysicianData: NotifyInternalData = {
      value: getValue(physicianDetailsResp, "primaryPhysician"),
      type: NotifyInternalDataType.PRIOR_AUTH_REQUEST_PRIMARY_PHYSICIAN_INFO,
    };
    notifyInternalDataUpdates(localPrimaryPhysicianData);
  }, [getValue(physicianDetailsResp, "primaryPhysician")]);

  useEffect(() => {
    if (
      saveUpdatePatientData &&
      saveUpdatePatientData.response &&
      saveUpdatePatientData.response.referralDetail &&
      saveUpdatePatientData.response.referralDetail.referralId != 0
    ) {
      setReferralId(
        saveUpdatePatientData.response.referralDetail.referralId.toString()
      );
      const localEmergencyContactData: NotifyInternalData = {
        value: saveUpdatePatientData.response.emergencyContact,
        type: NotifyInternalDataType.PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT,
      };
      notifyInternalDataUpdates(localEmergencyContactData);
      localAuthType(getValue(saveUpdatePatientData.response, "referralDetail.authorizationTypeId", MasterEndPointValues.HomeHealthIntermittent));
      navigate(
        ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE_BY_REFERRAL, {
          referralId: Number(
            saveUpdatePatientData.response.referralDetail.referralId
          ),
          patientId: Number(
            saveUpdatePatientData.response.referralDetail.patientId
          ),
          additionalParams: 0,
        }),
        { replace: true }
      );
      setdisableRedirect(true)
      handlePatientDetails(
        saveUpdatePatientData.response.referralDetail.referralId.toString(),
        patientId,
        loading
      );
      reset(saveUpdatePatientData.response);
      setPatientRequestDetail(saveUpdatePatientData.response);
    }
  }, [saveUpdatePatientData]);

  const FormSubmitErrorCheck = (content: any) => {
    if (
      (content &&
        content.error &&
        Object.keys(content.error).length > 0 &&
        content.error.status == 422) ||
      (content &&
        content.error &&
        Object.keys(content.error).length > 0 &&
        content.error.status == 400)
    ) {
      // TBD remove code later

      // Object.keys(content.error.data).forEach((field) => {
      //   const messages = content.error.data[field];
      //   setError(field as any, {
      //     type: "manual",
      //     message: messages.join(". ")
      //   });
      // });
      
      setHasFormError(true);
    } else {
      setHasFormError(false);
      if (
        saveDetailsClickType === 2 &&
        content.loading === false &&
        (content.error !== null || (content.error && Object.keys(content.error).length === 0))
      ) {
        navigateToAuthDetails(content);
      }
    }
    setFormError(content);
  };

  const navigateToAuthDetails = (
    content?: SavePatientRequestResponse | null
  ) => {
    const refId =
      getValue(content, "response.referralDetail.referralId", 0) > 0
        ? getValue(content, "response.referralDetail.referralId", 0)
        : referralId;
    navigate(
      ModuleLinks(PatientRecordsModule.AUTHORIZATION_VIEW, {
        Id: refId,
      })
    );
  };

  useEffect(() => {
    switch (currentTab) {
      case 0:
        FormSubmitErrorCheck(saveUpdatePatientData);
        break;
      case 1:
        FormSubmitErrorCheck(icd10Records);
        break;
      case 2:
        FormSubmitErrorCheck(referralIntakePhysicianState);
        break;
      case 3:
        FormSubmitErrorCheck(serviceProviderUpdateData);
        break;
      case 4:
        FormSubmitErrorCheck(serviceRequestUpdateData);
        break;
      case 5:
        FormSubmitErrorCheck(referralDocumentUpdateData);
        break;
      default:
        break;
    }
  }, [
    saveUpdatePatientData,
    icd10Records,
    referralIntakePhysicianState,
    referralDocumentUpdateData,
    serviceRequestUpdateData,
    serviceProviderUpdateData,
  ]);

  useEffect(() => {
    const userName = getValue(user, "email");
    if (currentTab === 0) {
      setValue("referralDetail.userCreated", userName);
      setValue(
        "referralDetail.patientEligibilityId",
        (response && response.patientEligibilityId) || 0
      );
      if (patientId) {
        setValue("referralDetail.patientId", Number(patientId));
      }
    }
    if (currentTab === 1) {
      const id = Number(referralId);
      setValue("userCreated", userName);
      setValue("id", id);
      setValue("typeId", DocumentFlowType.Auth);
    }
    if (currentTab === 2) {
      const id = Number(referralId);
      setValue("primaryPhysician.userCreated", userName);
      setValue("primaryPhysician.referralId", id);
      setValue("orderingPhysician.referralId", id);
    }

    if (currentTab == 4 || currentTab == 3) {
      setValue("referralId", referralId);
      setValue("userCreated", userName);
    }

    if (currentTab == 5) {
      setValue("userCreated", userName);
      setValue("id", referralId);
      setValue("type", DocumentFlowType.Auth);
    }
  });
  const submitReferral = () => {
    postReferralSubmit(
      {
        referralId: Number(localReferralId),
        userCreated: getValue(user, "email", null),
        userId: getValue(user, "userId", null),
      },
      true
    );
    reset(PatientInitialRequest);
  };

  const saveAndExit = (data: any) => {
    setSaveDetailsClickType(2);
    SubmitForm(data);
    resetReferralIntake();
  };

  const submitAndCreate = () => {
    setSaveDetailsClickType(2);
    submitReferral();
  };

  const saveDetails = (data: any) => {
    setSaveDetailsClickType(1);
    SubmitForm(data);
  };

  const SubmitForm = (data: any) => {
    switch (currentTab) {
      case 0:
        {
          const updatedResponse = {
            ...data,
            serviceAddress: {
              ...data.serviceAddress,
              patientState: matchState(
                getValue(data, "serviceAddress.patientState")
              ),
            },
            requester: {
              ...data.requester,
              requesterState: matchState(
                getConcatStringOrNull(data.requester.requesterState)
              ),
            },
            referralSource: {
              ...data.referralSource,
              streetName1: data.referralSource.streetName1 || null,
              streetName2: data.referralSource.streetName2 || null,
              county: data.referralSource.county || null,
              city: data.referralSource.city || null,
              referralSourceState:
                matchState(data.referralSource.referralSourceState) || null,
              zipCode: data.referralSource.zipCode || null,
              referralSourcePhoneNo:
                data.referralSource.referralSourcePhoneNo || null,
              referralSourceName:
                data.referralSource.referralSourceName || null,
              referralSourceFax: data.referralSource.referralSourceFax || null,
              referralSourceExtension:
                data.referralSource.referralSourceExtension || null,
              facilityTypeName: data.referralSource.facilityTypeName || null,
              facilityTypeId: data.referralSource.facilityTypeId || null,
            },
            referralDetail: {
              ...data.referralDetail,
              nonCoveredServices: !isEmpty(
                data.referralDetail.nonCoveredServices
              )
                ? data.referralDetail.nonCoveredServices.replace(/\s/g, "")
                : null,
              signedOrderDate:
                getValue(data, "referralDetail.signedOrderDate") || null,
            },
          };
          if (
            getValue(patientRequestDetails, "referralDetail.referralId", "") !=
            0
          ) {
            updateReferralDetail(updatedResponse, currentTab);
          } else {
            saveReferralDetail(updatedResponse, currentTab);
          }
        }
        break;
      case 1:
        if (Icd10RecordsResponse && Icd10RecordsResponse.id != 0) {
          updateReferralDetail(data, currentTab);
        } else {
          saveReferralDetail(data, currentTab);
        }
        break;
      case 2:
        {
          const primaryPhysicianAddress = {
            streetName1: getValue(data, "primaryPhysician.streetName1") || null,
            streetName2: getValue(data, "primaryPhysician.streetName2") || null,
            county: getValue(data, "primaryPhysician.county") || null,
            city: getValue(data, "primaryPhysician.city") || null,
            state: getValue(data, "primaryPhysician.state") || null,
            zipCode: getValue(data, "primaryPhysician.zipCode") || null,
          };
          const orderingPhysicianAddress = {
            streetName1:
              getValue(data, "orderingPhysician.streetName1") || null,
            streetName2:
              getValue(data, "orderingPhysician.streetName2") || null,
            county: getValue(data, "orderingPhysician.county") || null,
            city: getValue(data, "orderingPhysician.city") || null,
            state: getValue(data, "orderingPhysician.state") || null,
            zipCode: getValue(data, "orderingPhysician.zipCode") || null,
          };
          const primaryPhysician = {
            ...data.primaryPhysician,
            physicianState: matchState(
              getConcatStringOrNull(data.primaryPhysician.physicianState)
            ),
            physicianTypeId: getValue(data, "primaryPhysician.physicianTypeId") ? getValue(data, "primaryPhysician.physicianTypeId") : PhysicianTypeID.PRIMARY,
            ...primaryPhysicianAddress,
          };
          const orderingPhysician = {
            ...data.orderingPhysician,
            physicianState: matchState(
              getConcatStringOrNull(data.orderingPhysician.physicianState)
            ),
            physicianTypeId: getValue(data, "orderingPhysician.physicianTypeId") ? getValue(data, "orderingPhysician.physicianTypeId") : PhysicianTypeID.ORDERING,
            ...orderingPhysicianAddress,
          };
          const physicianPayload: PhysicianDetailsResponse = {
            ...data,
            primaryPhysician: getValue(primaryPhysician, "physicianName")
              ? primaryPhysician
              : null,
            orderingPhysician: getValue(orderingPhysician, "physicianName")
              ? orderingPhysician
              : null,
          };
          if (
            getValue(
              physicianPayload,
              "orderingPhysician.referralPhysicianDetailId"
            ) !== 0
          ) {
            updateReferralDetail(physicianPayload, currentTab);
          } else {
            saveReferralDetail(physicianPayload, currentTab);
          }
        }
        break;
      case 3:
        if (
          (serviceProviderResponse &&
            serviceProviderResponse.referralServiceProviderId != 0) ||
          getValue(
            serviceProviderUpdateData,
            "response.referralServiceProviderId",
            0
          ) > 0
        ) {
          const updatedResponse = {
            ...data,
            providerState: matchState(
              getConcatStringOrNull(data.providerState)
            ),
            phone: getValue(data, "phone") || null,
            extension: getValue(data, "extension") || null,
            referralServiceProviderId:
              getValue(
                serviceProviderUpdateData,
                "response.referralServiceProviderId",
                0
              ) > 0
                ? getValue(
                    serviceProviderUpdateData,
                    "response.referralServiceProviderId"
                  )
                : getValue(
                    serviceProviderResponse,
                    "referralServiceProviderId"
                  ),
          };
          updateReferralDetail(updatedResponse, currentTab);
        } else {
          const updatedResponse = {
            ...data,
            providerState: matchState(
              getConcatStringOrNull(data.providerState)
            ),
            phone: getValue(data, "phone") || null,
            extension: getValue(data, "extension") || null,
          };
          saveReferralDetail(updatedResponse, currentTab);
        }
        break;
      case 4:
        if (serviceRequestResponse && serviceRequestResponse.referralId != 0) {
          updateReferralDetail(data, currentTab);
        } else {
          saveReferralDetail(data, currentTab);
        }
        break;
      case 5:
        if (
          documentResponse &&
          documentResponse.id &&
          documentResponse.id != 0
        ) {
          updateReferralDetail(data, currentTab);
        } else {
          saveReferralDetail(data, currentTab);
        }
        break;
      case 6:
        submitReferral();
        break;
      default:
        break;
    }
  };

  const emergencyContactValues = useWatch({
    name: "emergencyContact",
    control,
  });

  const handleCloseAlert = () => {
    setIsCompleted(true);
    resetReferralIntake();
  };

  const handleBackClick = () => {
    setOpenAlert(false);
    setBackClick(false);
    resetReferralIntake();
    navigate(
      ModuleLinks(PatientRecordsModule.PATIENT_DETAILS_FORM, {
        patientId: patientId
          ? Number(patientId)
          : patientRequestDetails &&
            patientRequestDetails.referralDetail &&
            patientRequestDetails.referralDetail.patientId
          ? patientRequestDetails.referralDetail.patientId
          : 0,
      })
    );
  };

  const handleBackStep = () => {
    setOpenAlert(true);
    setBackClick(true);
  };

  return (
    <>
      <Breadcrumb
        patientId={response && response.patientId ? response.patientId : null}
        reset={resetReferralIntake}
        currentTab={currentTab}
        type={IntakeType.REFERRAL_INTAKE}
      />
      <Grid sx={ReferralGrid}>
        <Box sx={displayFlex}>
          <Grid sx={flexAlignCentre}>
            <Button
              variant="contained"
              sx={BackButton}
              onClick={handleBackStep}
            >
              BACK
            </Button>
            <Typography
              variant="h3"
              fontWeight={fontWeight.Weight[6]}
              color={colors.black[2]}
              ml={"1.5rem"}
              flex={"none"}
            >
              REFERRAL INTAKE
            </Typography>
            <Divider orientation="vertical" flexItem sx={DividerLine}></Divider>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[2]}
              color={colors.fonts[25]}
              flex={"none"}
            >
              Add referral details with respect to personal, source and address
              details below:
            </Typography>
          </Grid>
          <Grid sx={flexAllEnd} ml={"auto"}>
            <Permission
              controlId={`${PatientRecordsAccess.REFERRAL_INTAKE_SAVE_AND_EXIT_CREATE}||`}
            >
              <Button variant="contained" onClick={handleSubmit(saveAndExit)}>
                SAVE AND EXIT
              </Button>
            </Permission>
            <Permission
              controlId={`${PatientRecordsAccess.SAVE_DETAILS_CREATE}||`}
            >
              <Button variant="outlined" onClick={handleSubmit(saveDetails)}>
                SAVE DETAILS
              </Button>
            </Permission>
          </Grid>
        </Box>
        <DetailBox>
          <PatientHeader
            patientData={patientHeaderData}
            injectedTo={InjectedTo.ReferralIntake}
          />
          <PatientTab
            values={values}
            control={control}
            setValue={setValue}
            getValues={getValues}
            fields={fields}
            append={append}
            remove={remove}
            watch={watch}
            trigger={trigger}
            reset={reset}
            resetField={resetField}
            icd10RecordsFields={icd10RecordsFields}
            icd10RecordsAppend={icd10RecordsAppend}
            icd10RecordsRemove={icd10RecordsRemove}
            icd10Response={icd10Response}
            patientRequestDetail={patientRequestDetail}
            serviceRequestInfo={serviceRequestData}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            handleSubmit={handleSubmit(saveDetails)}
            emergencyContactValues={emergencyContactValues}
            clearErrors={clearErrors}
            useWatch={useWatch}
            physicianDetailsResp={physicianDetailsResp}
            formState={formState}
            formError={formError}
            serviceProvider={serviceProviderResponse}
            submitReferral={submitAndCreate}
            resetPhysicianRecords={resetPhysicianRecords}
            documentType={documentType || []}
            user={user}
            enableTabNumber={
              !isEmpty(Params) && getValue(getAtob(Params), "isAddService")
                ? getAtob(Params).intakeStageId - 1
                : null
            }
            authTypeId={authTypeId}
          />
        </DetailBox>
      </Grid>
      {!isCompleted && (
        <IncompleteDialog
          referralStage={getValue(referralSubmitResponse, "referralStage", [])}
          handleCloseAlert={handleCloseAlert}
          isCompleted={isCompleted}
          currentTab={currentTab}
          tabList={tabList}
          setIsCompleted={setIsCompleted}
        />
      )}
      {hasFormError && (
        <ErrorDialog
          hasFormError={hasFormError}
          setHasFormError={setHasFormError}
          formError={formError}
        />
      )}
      <AlertDialog
        alertMessage={BackClickAlertMessage}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        back={backClick}
        handleBackClick={handleBackClick}
      />
    </>
  );
};

export default ReferralIntake;
