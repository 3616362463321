import { styled } from "@mui/system";
import { FaDownload, FaPhone, FaUserMd, FaArrowRight } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaFax, FaSearch, FaRegEnvelope } from "react-icons/fa";
import { StyledComponent } from "@emotion/styled";
import {
  Popper,
  PopperProps,
  autocompleteClasses,
  Table,
  TableCell,
  TableHead,
  TableRow,
  styled as styledComponent,
} from "@mui/material";
import { alpha } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { colors, accent, others } from "../../../styles/colors";
import { DefaultLOF } from "../../../constants/Constants";
import { generateLofRowKeys } from "../../../utils";

export const InputSearchIconUI = () => ({
  color: colors.fonts[9],
  paddingRight: ".25rem",
  fontSize: "1.4rem",
});

export const DropZoneHolder = () => ({
  textAlign: "center",
  padding: "1rem",
  backgroundColor: colors.backgrounds[2],
  borderRadius: "10px",
});

export const FaPhoneInputIcon = styled(FaPhone)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary && props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const FaRegEnvelopeIcon = styled(FaRegEnvelope)(() => ({
  marginRight: "0.75rem",
  color: colors.primary.main,
  fontSize: "0.9rem",
}));

export const FaFaxIcon = styled(FaFax)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const FaSearchIcon = styled(FaSearch)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const FaPhoneInputDevider = () => ({
  marginRight: ".5rem",
  borderRightWidth: 0.7,
  height: "1.35rem",
});
export const NppesDivider = () => ({
  marginRight: ".5rem",
  marginLeft: ".5rem",
  borderRightWidth: 0.7,
  height: "5.5rem",
  paddingLeft: "0.6rem",
});

export const LOFInputDevider = () => ({
  marginTop: "1.1rem",
  borderRightWidth: 0.7,
  height: "3rem",
});

export const AddressInputBox = () => ({
  padding: "0 .5rem 0 .5rem",
});

export const ReferralAddressInputBox = () => ({
  padding: "1rem 2.2rem 0 1rem",
});

export const ReferralAddressInputBoxForZipcode = () => ({
  padding: "1rem 7rem 0 1rem",
});

export const AdditionalLabel = () => ({
  paddingRight: "2rem",
});

export const AdditionalDevider = () => ({
  paddingRight: "1rem",
});

export const VerticalDivider = () => ({
  height: "5rem",
  paddingRight: "1rem",
});

export const GenderInputUI = () => ({
  width: "100%",
});

export const DOBInputUI = () => ({
  width: "100%",
  maxWidth: "8.75rem",
});

export const PatientImageMain = () => ({
  backgroundColor: colors.backgrounds[2],
  borderRadius: "10px",
  padding: "1rem",
});

export const PatientImageOR = () => ({
  padding: ".25rem 0 .5rem 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ReferralImageMain = () => ({
  backgroundColor: "colors.backgroundColor[9]",
  borderRadius: "10px",
  padding: "1rem",
});

export const PatientImageRoot = () => ({
  padding: "0.5rem 2rem",
});

export const BrowsButtonUI = () => ({
  height: "1.65rem",
  paddingLeft: "1.1rem",
  paddingRight: "1.1rem",
});

export const FaDownloadIcon = styled(FaDownload)(() => ({
  fontSize: "1.05rem",
  color: colors.primary.main,
  cursor: "pointer",
}));

export const RiDeleteIcon = styled(RiDeleteBinLine)(() => ({
  fontSize: "1.35rem",
  color: accent.accentOrange[100],
  cursor: "pointer",
}));

export const FileUploadAvatar = () => ({
  height: "3.5rem",
  width: "3.5rem",
});

export const UserProfileAvatar = () => ({
  height: "2.5rem",
  width: "2.5rem",
});

export const DocumentRoot = () => ({
  margin: "1.5rem 1rem 0  0",
  borderRadius: "10px",
  backgroundColor: colors.backgroundColor[2],
});

export const ReferralDocMain = () => ({
  height: "5.063rem",
  paddingTop: "0.3rem",
});

export const ReferralDocList = () => ({
  backgroundColor: colors.backgroundColor[2],
  padding: "0 2.5rem 0.5rem 2.5rem",
  borderRadius: "0 0 0.625rem 0.625rem",
  overflowY: "scroll",
  height: "7rem",
});

export const ReferralDocumentList = () => ({
  backgroundColor: colors.backgroundColor[2],
  padding: "0 2.5rem 0.5rem 2.5rem",
  borderRadius: "0 0 0.625rem 0.625rem",
});

export const FileText = () => ({
  marginTop: "1rem",
  textAlign: "center",
});

export const spanStyle = () => ({
  color: colors.red[100],
});

export const fileName = () => ({
  width: "15rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  marginRight: "1rem",
});

export const dragAndDropStyle = {
  minHeight: "5.1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `0.5px dashed ${colors.backgroundColor[9]}`,
  width: "40%",
  height: "100%",
};

export const acceptStyle = {
  border: `0.5px dashed ${colors.borders[8]}`,
};

export const rejectStyle = {
  border: `0.5px dashed ${colors.red[101]}`,
};

export const numberTextField = {
  "& .MuiInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      webkitAppearance: "none",
    },
  },
};
export const FaxFormInputGrid = () => ({
  display: "initial",
});

export const StyledPopper: StyledComponent<PopperProps> = styledComponent(
  Popper
)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
    "& .MuiAutocomplete-option": {
      padding: "unset",
      cursor: "auto",
    },
    "&::-webkit-scrollbar": {
      width: ".25rem",
      height: "0.3rem",
      paddingLeft: "2rem",
    },
    "&::-webkit-scrollbar-track": {
      width: 0.4,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors.black[6],
      borderRadius: 0.5,
      width: 0.3,
    },
  },
});

export const AutoCompletePaper = (width?: string) => ({
  "&.MuiAutocomplete-paper": {
    width: width || "42rem",
    height: "fit-content",
    border: `1px solid ${colors.borders[1]}`,
    marginLeft: "0rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
});

export const NppesAutoCompletePaper = (width?: string) => ({
  "&.MuiAutocomplete-paper": {
    minWidth: width || "35vw",
    height: "fit-content",
    marginLeft: "0rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
});

export const AutocompleteTableHolder = () => ({
  width: "inherit",
  top: "1rem",
  borderRadius: "0.19rem",
  paddingBottom: "1rem",
});

export const AutocompleteRow = () => ({
  textTransform: "capitalize",
  paddingTop: "0.2rem",
});

export const TableHeadMargin = (isHeader: boolean) => ({
  marginTop: isHeader ? "1rem" : "auto",
});

export const dropDownLabel = {
  color: colors.black[5],
  opacity: "1",
};

export const TableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderRight: "none",
    borderLeft: "none",
    paddingLeft: "0.5rem",
    paddingRight: "0.7rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderRight: "1rem",
  },
  "& th:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
  },
}));

export const LofTableMain = styled(Table)(() => ({
  "& .MuiTableCell-root": {
    borderLeft: "none",
    paddingLeft: "0.5rem",
    paddingRight: "0.7rem",
  },
  "& th:first-of-type": {
    borderRadius: "0.7rem 0 0 0.7rem",
    borderRight: "1rem",
  },
  "& th:last-of-type": {
    borderRadius: "0 0.7rem 0.7rem 0",
  },
}));

export const TableMainHead = styled(TableHead)(() => ({
  backgroundColor: colors.backgroundColor[8],
}));

export const LofTableMainHead = styled(TableHead)(() => ({
  backgroundColor: colors.backgroundColor[2],
}));

export const TableMainHeadRow = styled(TableRow)(() => ({
  height: "3rem",
}));

export const TableCellStickyLeftSecond = styled(TableCell)(() => ({
  left: 44,
  position: "sticky",
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableCellStickyHeaderLeftSecond = styled(TableCell)(() => ({
  left: 44,
  position: "sticky",
  borderBottom: "none",
  backgroundColor: colors.backgroundColor[8],
}));

export const TableCellHeaderLeft = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  borderBottom: "none",
  backgroundColor: colors.backgroundColor[8],
}));

export const LofTableCellHeaderLeft = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  backgroundColor: colors.backgroundColor[14],
}));

export const LofFirstTableCellStickyHeaderLeftSecond = styled(TableCell)(
  () => ({
    left: "12.6rem",
    position: "sticky",
    backgroundColor: colors.backgroundColor[14],
  })
);

export const LofSecondTableCellStickyHeaderLeftSecond = styled(TableCell)(
  () => ({
    left: "25rem",
    position: "sticky",
    backgroundColor: colors.backgroundColor[14],
    zIndex: 1,
    borderRight: `1px solid ${colors.dropShadow[1]}`,
  })
);

export const LofLofHeaderTitleStyle = (indexForRecordWithTypeTwo: number) => ({
  width: indexForRecordWithTypeTwo === 1 ? "24rem" : "39rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const LoffirstFixedTableCellBorder = (
  LevelOfFunctionRsponse: number
) => ({
  borderRight:
    LevelOfFunctionRsponse === 1 ? `1px solid ${colors.dropShadow[1]}` : "none",
});

export const AlignCenter = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

export const UnusedBox = {
  paddingLeft: "1rem",
};

export const Flex = () => ({
  display: "flex",
  backgroundColor: alpha(colors.black[7], 0.35),
});

export const FullNameBoxStyle = {
  width: "auto",
  display: "flex",
  alignItems: "center",
};

export const PatientTableCell = (removeBorder: boolean) => ({
  borderRight: 0,
  padding: 0,
  textAlign: "left",
  paddingRight: "1%",
  paddingLeft: "0rem",
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const SortBox = () => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0.5rem",
  svg: {
    "&:first-of-type": {
      marginBottom: "-0.8rem",
    },
  },
});

export const TableCellSticky = styled(TableCell)(() => ({
  position: "sticky",
  right: 0,
  background: others.otherColors[33],
}));

export const TableCellStickyHeader = styled(TableCell)(() => ({
  borderBottom: "none",
  "&.MuiTableCell-root:last-child": {
    backgroundColor: colors.backgroundColor[8],
    position: "sticky",
    right: 0,
  },
}));

export const TableCellStickyLeft = styled(TableCell)(() => ({
  position: "sticky",
  left: 0,
  background: others.otherColors[33],
  zIndex: "1",
}));

export const TableNameDetails = (removeBorder: boolean) => ({
  textDecoration: "underline",
  textDecorationColor: colors.fonts[3],
  borderRight: 0,
  textAlign: "left",
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const checkBoxStyle = {
  paddingLeft: "1rem",
  "& span": {
    padding: 0,
  },
};

export const TableCellcheckBoxStyle = (removeBorder: boolean) => ({
  "& span": {
    padding: 0,
  },
  borderBottom: removeBorder ? "unset" : `1px solid ${colors.dropShadow[1]}`,
});

export const LofHeaderBoxStyle = {
  maxWidth: "11.45rem",
  width: "11.45rem",
  paddingLeft: "0.5rem",
};

export const LofFirstCellHeader = {
  width: "11rem",
};

export const LofTableCellWidth = {
  maxWidth: "11.45rem",
  width: "11.45rem",
};
export const LofLatestTableCellWidth = {
  maxWidth: "11.45rem",
  width: "11.45rem",
};

export const LofLatestColumn = (
  indexForRecordWithTypeTwo: number,
  index: number
) => ({
  backgroundColor:
    indexForRecordWithTypeTwo === index
      ? colors.backgroundColor[7]
      : colors.backgroundColor[14],
});

export const LofBorderBottom = (rowKey: boolean) => ({
  borderBottom: rowKey === true ? "1" : "none",
});

export const LofTableCellBorderBottom = (rowKey: boolean) => ({
  borderBottom: LofBorderBottom(rowKey),
  backgroundColor: colors.backgroundColor[13],
});

export const LofTableCellBorder = (
  rowKey: boolean,
  LevelOfFunctionDataResp: number
) => ({
  borderBottom: LofBorderBottom(rowKey),
  borderRight:
    LevelOfFunctionDataResp === 1
      ? `1px solid ${colors.dropShadow[1]}`
      : "none",
  backgroundColor: colors.backgroundColor[13],
});

export const LofScrollLableTableCellStyle = (
  rowKey: boolean,
  columnId: string,
  indexForRecordWithTypeTwo: number
) => ({
  borderBottom: LofBorderBottom(rowKey),
  backgroundColor:
    parseInt(columnId.match(/\d+/)?.[0] ?? "NaN", 10) ===
    indexForRecordWithTypeTwo + 1
      ? colors.backgroundColor[7]
      : colors.backgroundColor[13],
});

export const LofRowColumnsStyleTooltip = (rowKey: string) => ({
  paddingTop: generateLofRowKeys(DefaultLOF).includes(rowKey)
    ? "2.3rem"
    : "0rem",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "15rem",
});

export const ViewButton = {
  width: "4rem",
};

export const TableComponentGrid = () => ({
  padding: "0.6rem",
});

export const TimePickerIcon: any = styled(AccessTimeIcon)((props) => ({
  marginRight: ".25rem",
  color: props.theme.palette.primary.main,
  fontSize: "1.4rem",
  marginTop: "1.35rem",
  padding: 0,
}));

export const timePickerField = () => ({
  padding: "0px 6px",
  fontSize: ".7rem",
  fontWeight: 500,
  lineHeight: 1.65,
});

export const timePickerFieldMargin = () => ({
  marginTop: "1.45rem",
});

export const timePickerSpacing = () => ({
  pr: 1.5,
});

export const TableBodyRow = () => ({
  height: "3.2rem",
});

export const HeaderTableCell = () => ({
  borderBottom: "unset",
  "&.MuiTableCell-root:last-child": {
    borderBottom: "none",
  },
});

export const DownIcon = {
  marginTop: "0.2rem",
};

export const ContainerStyle = {
  "&::-webkit-scrollbar": {
    width: "0.5rem",
    height: "0.2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
    marginLeft: "10rem",
    marginRight: "50rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
};

export const LofContainerStyle = {
  "&::-webkit-scrollbar": {
    width: "1rem",
    height: "0.2rem",
  },
  "&::-webkit-scrollbar-track": {
    width: 0.1,
    marginLeft: "10rem",
    marginRight: "50rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.black[7],
    borderRadius: 0.1,
    width: 0.1,
  },
};

export const FaUserMdIcon = styled(FaUserMd)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary && props.theme.palette.primary.main,
  fontSize: ".95rem",
}));

export const FaRightArrowIcon = styled(FaArrowRight)((props) => ({
  marginRight: "0.75rem",
  color: props.theme.palette.primary && props.theme.palette.primary.main,
  fontSize: ".69rem",
}));

export const LofTableMainStyle = (LofLength: any[]) => ({
  width: LofLength && LofLength.length <= 7 ? "25%" : "100%",
});

export const TableBodyCheckbox = () => ({
  paddingLeft: "1rem",
});

export const PhysicianNameCol = () => ({
  width: "100%",
  padding: "0.5rem",
  paddingLeft: "1rem",
  textAlign: "left",
  wordBreak: "break-word",
});

export const PhysicianNpiCol = () => ({
  width: "100%",
  padding: "0.5rem",
  textAlign: "center",
});

export const SelectPhysicianBtn = () => ({
  width: "100%",
  pl: "1.4rem",
  textAlign: "center",
});

export const PhysicianRecordHeader = () => ({
  backgroundColor: others.MainBackground[1],
  width: "100%",
  padding: "0.5rem",
  paddingLeft: "0.5rem",
  textAlign: "center",
});

export const AvailableRecords = () => ({
  height: "2rem",
  padding: "0.5rem",
  paddingLeft: "1rem",
});

export const TimepickerHourMinute = () => ({
  top: "3.4em",
});

export const TimePickerError = () => ({
  width: "100%",
});

export const SearchedResultTableHead = () => ({
  padding: "0.5rem",
  backgroundColor: colors.backgroundColor[8],
  borderBottom: 0,
  "&.MuiTableCell-root:first-of-type": {
    borderTopLeftRadius: "0.19rem",
    borderBottomLeftRadius: "0.19rem",
    paddingLeft: "1.5rem",
  },
  "&.MuiTableCell-root:last-child": {
    borderTopRightRadius: "0.19rem",
    borderBottomRightRadius: "0.19rem",
  },
});

export const ReadOnlyFieldContainer = () => ({
  display: "inline-flex",
  flexDirection: "column",
  position: "relative",
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,
  verticalAlign: "top",
  width: "100%",
  height: "3.024rem",
  color: colors.black[1],
});

export const ReadOnlyFieldLabel = () => ({
  position: "absolute",
  left: 0,
  top: 0,
  whiteSpace: "nowrap",
});

export const ReadOnlyFieldValue = () => ({
  marginTop: "1.3rem",
  height: "1.699rem",
  boxSizing: "border-box",
  display: "inline-flex",
  alignItems: "center",
  width: "100%",
});

export const ReadOnlyFieldIcon = () => ({
  paddingRight: "0.5rem",
  marginTop: "0.3rem",
});
